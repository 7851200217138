<template>
  <footer class="footer">
    <div class="container">
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}, Made with
        <i class="fa fa-heart" style="font-size:16px;color:red"></i> By
        Infinitybrains.
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
