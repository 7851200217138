import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";
import policy from "@/pages/policy";
import terms_condition from "@/pages/termscondition";

import landingPage from "@/pages/landing-page";
// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";
// Profile
import UserProfile from "@/pages/Dashboard/Examples/UserProfile.vue";

// User Management
import ListUserPage from "@/pages/Dashboard/Examples/UserManagement/ListUserPage.vue";

// Pages
import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";
import PasswordReset from "@/pages/Dashboard/Pages/PasswordReset.vue";
import Register from "@/pages/Dashboard/Pages/Register.vue";

// Components pages
import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
import Icons from "@/pages/Dashboard/Components/Icons.vue";
import Typography from "@/pages/Dashboard/Components/Typography.vue";

// VacancyList

import VacancyList from "@/pages/Dashboard/Vacancy/VacancyList.vue";
import Vacancy from "@/pages/Dashboard/Vacancy/Vacancy.vue";
import VacancyDetails from "@/pages/Dashboard/Vacancy/VacancyDetails.vue";

import VacancyCreate from "@/pages/Dashboard/Vacancy/VacancyCreate.vue";

import CondidateList from "@/pages/Dashboard/Candidate/CondidateList.vue";

import PasswordChange from "@/pages/Dashboard/Candidate/PasswordChange.vue";

import ConsltantList from "@/pages/Dashboard/Consltant/ConsltantList.vue";

import TermsCondition from "@/pages/Dashboard/Terms/TermsCondition.vue";
// TableList pages
import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";

// Maps pages
import FullScreenMap from "@/pages/Dashboard/Maps/FullScreenMap.vue";

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

// import Payment from "@/pages/Dashboard/Payment/Payment.vue";
import VerifyEmail from "@/pages/Dashboard/Payment/VerifyEmail.vue";
import Success from "@/pages/Dashboard/Payment/Success.vue";

// import IBTalks and Blog pages here
import IbTalks from "../components/IB_Talks_And_Blogs/IB_Talks_Page.vue";
import The_Phoenix_Tale_Stories from "../components/IBTalksBlog/ThePhoenixTaleStories.vue";
import AllBlogs from "../components/IB_Talks_And_Blogs/All_Blogs.vue";
import Blogs from "../components/IB_Talks_And_Blogs/Blogs.vue";

import Pop_Up_Comp from "../components/New_Pop_Up/New_Pop_Up_Comp.vue";

// import Temporary plans page components

import Temporary_Plan_Design from "../pages/Temporary_plan_payment/Temporary_Plan_Design.vue";
import Temporary_Plan_Billing_Design from "../pages/Temporary_plan_payment/Temporary_plan_billingDesign.vue";
import Success_Comp from "../pages/success/Success.vue";

// Tutorial Page Component

import TutorialPage from "../pages/TutorialPage/TutorialPage.vue";

let componentsMenu = {
	path: "/components",
	component: DashboardLayout,
	redirect: "/components/notification",
	name: "Components",
	children: [
		{
			path: "table",
			name: "Table",
			components: { default: RegularTables },
			meta: { middleware: auth },
		},
		{
			path: "typography",
			name: "Typography",
			components: { default: Typography },
			meta: { middleware: auth },
		},
		{
			path: "icons",
			name: "Icons",
			components: { default: Icons },
			meta: { middleware: auth },
		},
		{
			path: "maps",
			name: "Maps",
			meta: {
				hideContent: true,
				hideFooter: true,
				navbarAbsolute: true,
				middleware: auth,
			},
			components: { default: FullScreenMap },
		},
		{
			path: "notifications",
			name: "Notifications",
			components: { default: Notifications },
			meta: { middleware: auth },
		},
		{
			path: "rtl",
			name: "وحة القيادة",
			meta: {
				rtlActive: true,
				middleware: auth,
			},
			components: { default: RtlSupport },
		},
	],
};

let examplesMenu = {
	path: "/password",
	component: DashboardLayout,
	name: "Pass",
	children: [
		{
			path: "user-profile",
			name: "User Profile",
			components: { default: UserProfile },
			meta: { middleware: auth },
		},
		{
			path: "user-management/list-users",
			name: "List Users",
			components: { default: ListUserPage },
			meta: { middleware: auth },
		},
	],
};

let vacancy = {
	path: "/vacancys",
	component: DashboardLayout,
	name: "vacancys",
	children: [
		{
			path: "VacancyList",
			name: "VacancyList",
			components: { default: VacancyList },
			meta: { middleware: auth },
			redirect: "/vacancys/VacancyList/Vacancy",
			children: [
				{
					path: "Vacancy",
					name: "Current Vacancies",
					component: Vacancy,
				},
				{
					path: "VacancyDetails/:id",
					name: "VacancyDetails/:id",
					component: VacancyDetails,
				},
			],
		},
	],
};

let candidate = {
	path: "/candidate",
	component: DashboardLayout,
	name: "candidate",
	children: [
		{
			path: "candidate",
			name: "List of Candidates",
			components: { default: CondidateList },
			meta: { middleware: auth },
		},
		{
			path: "/password-change",
			name: "Change your Password",
			components: { default: PasswordChange },
			meta: { middleware: auth },
		},
		// {
		//   path: "/payment",
		//   name: "Payment",
		//   components: { default: Payment },
		//   meta: { middleware: auth },
		// },
		{
			path: "/VerifyEmail",
			name: "VerifyEmail",
			components: { default: VerifyEmail },
			meta: { middleware: auth },
		},
		{
			path: "/Success",
			name: "Success",
			components: { default: Success },
			meta: { middleware: auth },
		},
	],
};

let terms = {
	path: "/terms",
	component: DashboardLayout,
	name: "Terms",
	children: [
		{
			path: "terms",
			name: "Terms & Conditions",
			components: { default: TermsCondition },
			meta: { middleware: auth },
		},
	],
};

let vacancycreate = {
	path: "/vacancycreate",
	component: DashboardLayout,
	name: "vacancycreate",
	children: [
		{
			path: "VacancyCreate",
			name: "Create Vacancies",
			components: { default: VacancyCreate },
			meta: { middleware: auth },
		},
	],
};

let consltantList = {
	path: "/consultantList",
	component: DashboardLayout,
	name: "ConsultantList",
	children: [
		{
			path: "consultantList",
			name: "List of Consultants",
			components: { default: ConsltantList },
			meta: { middleware: auth },
		},
	],
};

let authPages = {
	path: "/",
	component: AuthLayout,
	name: "Authentication",
	children: [
		{
			path: "/login",
			name: "Login",
			component: Login,
			meta: { middleware: guest },
		},

		{
			path: "/reset-password",
			name: "PasswordReset",
			component: PasswordReset,
			meta: { middleware: guest },
		},
		{
			path: "/register",
			name: "Register",
			component: Register,
			meta: { middleware: guest },
		},
	],
};

const routes = [
	{
		path: "/",
		name: "landingPage",
		component: landingPage,
		// meta: { middleware: guest },
	},
	{
		path: "/policy",
		name: "policy",
		component: policy,
		// meta: { middleware: guest },
	},
	{
		path: "/terms-condition",
		name: "terms_condition",
		component: terms_condition,
		// meta: { middleware: guest },
	},
	{
		path: "/login",
		component: candidate,
		meta: { middleware: auth },
		children: [
			{
				path: "dashboard",
				name: "Dashboard",
				components: { default: Dashboard },
				meta: { middleware: auth },
			},
		],
	},

	// ibtalks and blogs here
	{
		name: "ib-talks",
		path: "/ib-talks",
		component: IbTalks,
	},
	{
		name: "The Phoenix Tale",
		path: "/the-phoenix-tale",
		component: The_Phoenix_Tale_Stories,
	},
	{
		name: "all-blogs",
		path: "/all-blogs",
		component: AllBlogs,
	},
	{
		name: "blogs",
		path: "/blogs/:id",
		component: Blogs,
	},
	{
		name: "Pop_Up_Comp",
		path: "/pop-up",
		component: Pop_Up_Comp,
	},

	// Temporary Plan Payment
	{
		path: "/TemporaryPlans",
		name: "TemporaryPlans",
		component: Temporary_Plan_Design,
	},
	{
		path: "/TemporaryPlansBilling",
		name: "TemporaryPlansBilling",
		component: Temporary_Plan_Billing_Design,
	},
	{
		path: "/success",
		name: "Success",
		component: Success_Comp,
	},
	{
		path: "/tutorialPage",
		name: "TutorialPage",
		component: TutorialPage,
	},

	componentsMenu,
	examplesMenu,
	authPages,
	vacancy,
	candidate,
	vacancycreate,
	terms,
	consltantList,
];

export default routes;
