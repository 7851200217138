<template>
  <div class="tutorial-container">
    <NavBarComp />

    <div class="tutorial-main-div">
      <div class="tutorial-header">
        <img src="./assets/header_bg.png" alt="header_bg" class="header-bg" />
        <div class="tutorial-details">
          <div class="tutorial-header-desc">
            <img src="./assets/ib_logo.png" alt="ib_logo" />
            <h3>LEARN AND GROW WITH OUR TUTORIALS</h3>
            <h4>
              The header serves as the navigational backbone of the page. It
              includes the website logo for brand recognition, a navigation bar
              for easy access to key sections, and a search bar for quick
              tutorial lookups.
            </h4>
          </div>
          <div class="tutorial-header-img-div">
            <img src="./assets/Group_44667.png" alt="header-img" />
          </div>
        </div>
      </div>
      <div class="tutorial-heading">
        <h3>HOW CONSULTANT PANEL WORKS?</h3>
        <h4>Step-By-Step Guides To Enhance Your Skills</h4>
      </div>
      <div class="tutorial-first-video-section">
        <!-- CP WEB -->
        <div class="tutorial-video-div">
          <div class="video-img-div" @click="redirect(web_cp)">
            <img src="./assets/cp_laptop.png" alt="video-img" />
          </div>
          <div class="video-desc-div">
            <h3 @click="redirect(web_cp)">
              HOW DOES CONSULTANT PANEL WEBSITE WORK?
            </h3>
            <h4 @click="redirect(web_cp)">
              The header serves as the navigational backbone of the page. It
              includes the website logo for brand recognition, a navigation bar
              for easy access to key sections, and a search bar for quick
              tutorial lookups.
            </h4>
            <button
              type="button"
              class="tutorial-btn"
              @click="redirect(web_cp)"
            >
              <div class="inside-button">
                <h3>WATCH NOW</h3>
                <img src="./assets/play_icon.svg" alt="play-icon" />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="tutorial-heading heading-2">
        <h3>WHAT IS INFINITYBRAINS ?</h3>
        <h4>Step-By-Step Guides To Enhance Your Skills</h4>
      </div>
      <div class="tutorial-second-video-section">
        <div class="tutorial-video-div-2" @click="redirect(video_one_hour)">
          <div class="video-img-div-2" @click="redirect(video_one_hour)">
            <img src="./assets/video-2-img.png" alt="video-2-img" />
          </div>
          <div class="video-desc-2" @click="redirect(video_one_hour)">
            <h3>
              Infinity Brains Product Showcase: How Our Apps and Web Platforms
              Work
            </h3>
            <h4>
              Welcome to Infinity Brains! In this comprehensive one-hour video,
              we showcase all of our company's innovative products through
              detailed screen recordings. You'll learn how our apps and websites
              work, making it easy for users to navigate and utilize our
              technology. Plus, we've sprinkled in some funny clips to keep
              things entertaining. Join us and discover the functionality and
              fun behind Infinity Brains' products!
            </h4>
            <button
              type="button"
              class="tutorial-btn"
              id="second-video-btn"
              @click="redirect(video_one_hour)"
            >
              <div class="inside-button">
                <h3>WATCH NOW</h3>
                <img src="./assets/play_icon.svg" alt="play-icon" />
              </div>
            </button>
          </div>
        </div>
      </div>

      <div class="blue-area-div"></div>
    </div>

    <Footer />
  </div>
</template>

<script>
import NavBarComp from "../../components/NavBarComp/NavBarComp.vue";
import Footer from "../../components/Footer/Footer.vue";

import "./style/TutorialPage.css";

import Swal from "sweetalert2";

export default {
  name: "TutorialPage",
  components: {
    NavBarComp,
    Footer
  },
  data() {
    return {
      web_cp: "",
      video_one_hour: "https://youtu.be/fcODYjNaQ7s?si=TQfr6QfdZ3g2ZjyS"
    };
  },
  methods: {
    redirect(url) {
      if (url !== "") {
        window.location.href = url;
      } else {
        Swal.fire({
          title: "Video Uploading In Progress!!",
          text: "That video content will be uploaded soon!!",
          icon: "info"
        });
      }
    }
  }
};
</script>

<style scoped>
.blue-area-div {
  width: 100%;
  height: 50px;
  margin-top: 24px;
  margin-bottom: 12px;
  background-color: #0c2d61;
}
</style>
