<template>
  <md-card class="md-card-login" :class="{ 'md-card-hidden': cardHidden }">
    
    <md-card-content style="padding: 0; ">
      <div
        class="social-line"
        style="font-weight: bold; font-size: 20px; background-color: #012A71; color: white; margin-bottom: 10px; border-radius: 5px 5px 0 0;"
      >
        Signup
      </div>
    </md-card-content>

    <md-card-content style="padding: 0px 20px ; width: 100%;">
      <slot name="description"></slot>
      <slot name="signup"></slot>
    
    </md-card-content>

    <md-card-actions style="justify-content: space-evenly !important;">
      <slot name="footer"></slot>
    </md-card-actions>
  </md-card>
</template>

<script>
export default {
  name: "signup-card",
  props: {
    headerColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cardHidden: true,
      
    };
  },
  beforeMount() {
    setTimeout(this.showCard, 400);
  },
  methods: {
    showCard: function() {
      this.cardHidden = false;
    },
    getClass: function(headerColor) {
      return "md-card-header-" + headerColor + "";
    },
  },
};
</script>

<style lang="css"></style>

