import Vue from "vue";
import router from "@/router";
import { VueAuthenticate } from "vue-authenticate";
import Swal from "sweetalert2";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
	baseUrl: process.env.VUE_APP_API_BASE_URL,
	tokenName: "access_token",
	loginUrl: "/login",
	registerUrl: "/register",
});

export default {
	state: {
		isAuthenticated:
			localStorage.getItem("vue-authenticate.vueauth_access_token") !== null,
	},

	getters: {
		isAuthenticated(state) {
			return state.isAuthenticated;
		},
	},

	mutations: {
		isAuthenticated(state, payload) {
			state.isAuthenticated = payload.isAuthenticated;
		},
	},

	actions: {
		login(context, payload) {
			return vueAuth
				.login(payload.user, payload.requestOptions)
				.then((response) => {
					if (
						response.data.data.policy == "0" &&
						response.data.data.user_type == "2"
					) {
						Swal.fire({
							confirmButtonText: "Accept",
							confirmButtonColor: "#66bb6a",

							html: `
    
     <div class="md-caption" style="text-align: left;" >
 
       <h3>Agreement</h3>
       
       Date:<br>
       Consultant Name:<br>
       Authorized Person Name and Contact Number:<br>
       Contact Person Name and Contact Number:<br>
       Address of Placement Consultant:<br>
       This agreement is signed between Redjinni Industries Private Ltd and the Placement Consultant - ……….<br>
       As per the agreement, Red jinni Industries Private Ltd is hiring the Consultant for appointment of the manpower for the organization. The terms and conditions for the same are as under:<br>
       PROFESSIONAL FEES STRUCTURE:- Assignments for all positions such as Corporate, Senior Management, Middle & Junior Management & Support Staff functions are undertaken on an equal basis:- <span class="text-danger">(Fees for services of consultant for the position of back office  and all other office/sales staff will be as per their 1 month of salary per candidate)</span><br>
       If the selected candidate resigns our organization or we terminate the candidate from his/her duty within 90 days from date of joining the consultant agrees to provide a free replacement for same or any other position.(Redjinni does not ask for Replacement guarantee in case of delivery boy/Fresher)<br>
       PAYMENT: - The Consultant shall raise an Invoice on 54 days from the Date of Joining of the Candidate. All the payments will be settled within 10 days from the date the invoice is raised.<br>
       This agreement is valid till from 1st Jan., 2022 to 31st Dec, 2022. Aside, in case the manpower consultant is not able to provide a single manpower within 90 days, this agreement is automatically gets revoked.<br>
       Thanking you<br>
       FROM RED JINNI INDUSTRIES PVT LTD 	<br>		Consultant’s SIGNATURE & COMPANY’S SEAL<br>
       Authorized Signatory (I have read and agree with term and conditions)<br>
       
              <p style="text-align: center; margin-bottom: 0px;"> REDJINNI INDUSTRIES PVT LTD<br>
       504- Luxuria Business Hub, Vesu, Surat.</p>
       
        
     </div>`,
						}).then((result) => {
							if (result.value == true) {
								axios.defaults.headers.common["Authorization"] =
									"Bearer " + response.data.data.authorization;
								axios
									.patch(
										`${process.env.VUE_APP_API_BASE_URL}user/${response.data.data.id}`,
										{ policy: "1" }
									)
									.then((result) => {
										if (result.data.status_code == "200") {
											localStorage.setItem(
												"vue-authenticate.vueauth_access_token",
												response.data.data.authorization
											);
											localStorage.setItem(
												"data",
												response.data.data.user_type
											);
											context.commit("isAuthenticated", {
												isAuthenticated: vueAuth.isAuthenticated(),
											});
											router.push({ name: "Home" });
										}
									});
							}
						});
					} else {
						// console.log("Login Response: ", response);
						localStorage.setItem(
							"vue-authenticate.vueauth_access_token",
							response.data.data.authorization
						);

						const givenDateStr = response.data.data.created_at;
						// const givenDateStr = "2024-04-10 13:54:06";
						const comparisonDate = new Date(2024, 4, 1); // May 1st, 2024 (month index is 0-based)

						// Parse the given date string into a Date object
						const givenDate = new Date(givenDateStr);

						var newUser = null;
						var answer = null;
						var planExpire = null;

						if (givenDate > comparisonDate) {
							newUser = "Yes";
							// console.log("Yes");
						} else {
							newUser = "No";
							// console.log("No");
						}

						if (
							response.data.subscription_data != null &&
							response.data.subscription_data.exp_at != null
						) {
							const expiryString = response.data.subscription_data.exp_at;

							// Parse the expiry string into a Date object
							const expiryDate = new Date(expiryString);

							// Get the current date and time
							const currentDate = new Date();

							// Compare expiry date with current date (considering time)
							if (expiryDate < currentDate) {
								planExpire = "Yes";
							} else {
								planExpire = "No";
							}
						}

						// console.log(
						// 	"User has Subscription: ",
						// 	response.data.subscription_data == null && newUser == "Yes"
						// 		? "No"
						// 		: "Yes"
						// );

						if (response.data.subscription_data == null && newUser == "Yes") {
							answer = "No";
						} else if (
							response.data.subscription_data != null &&
							newUser == "Yes" &&
							planExpire == "Yes"
						) {
							answer = "No";
						} else {
							answer = "Yes";
						}

						// console.log("Subscription Answer: ", answer);

						localStorage.setItem("is_subscribed", answer);
						localStorage.setItem("data", response.data.data.user_type);
						context.commit("isAuthenticated", {
							isAuthenticated: vueAuth.isAuthenticated(),
						});
						router.push({ name: "Home" });
					}
				});
		},

		register(context, payload) {
			return vueAuth.register(payload.user, payload.requestOptions).then(() => {
				context.commit("isAuthenticated", {
					isAuthenticated: vueAuth.isAuthenticated(),
				});
				router.push({ name: "Home" });
			});
		},

		logout(context) {
			return vueAuth.logout().then(() => {
				context.commit("isAuthenticated", {
					isAuthenticated: vueAuth.isAuthenticated(),
				});
				router.push({ name: "Login" });
			});
		},
	},
};
