<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card-content style="padding: 0;">
        <div
          class="md-toolbar-row"
          style="border: 1px solid #012A71; background-color: #012A71; color: white; font-size: 25px; padding: 0 20px; border-radius: 15px; font-weight: bolder;"
        >
          <div class="md-toolbar-section-start">List of Candidates</div>
          <div class="md-toolbar-section-end">
            <div class="md-size-15">
              <md-field style="width: 70px; margin-right: 20px;">
                <!-- <label>Per page</label> -->
                <md-select
                  v-model="pagination.perPage"
                  name="pages"
                  style="background-color: white; border: 1px solid white; border-radius: 5px"
                >
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-size-30">
              <md-field class="md-invalid">
                <!-- <label>Search</label> -->
                <md-input
                  placeholder="Search"
                  v-model="query"
                  style="background-color: white; border: 1px solid white; border-radius: 5px; padding-left: 10px"
                />
              </md-field>
            </div>
          </div>
        </div>
      </md-card-content>
      <md-card
        style="margin-top: 15px; border: 1px solid white; border-radius: 15px;"
      >
        <md-card-content>
          <md-table
            :value="dataall"
            :md-sort.sync="sortation.field"
            :md-sort-order.sync="sortation.order"
            :md-sort-fn="customSort"
            class="table-striped table-hover"
          >
            <p v-if="!dataall" style="font-weight: bolder;">No Data</p>

            <!-- <md-table-toolbar>
              <div class="md-layout  ">
                <div class="md-layout-item md-size-15">
                  <md-field>
                    <label>Per page</label>
                    <md-select v-model="pagination.perPage" name="pages">
                      <md-option
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                        {{ item }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>

              <md-field>
                <label>Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field class="md-invalid">
              <label>Search</label>
              <md-input v-model="query"   />
             
               </md-field>
            </md-table-toolbar> -->
            <!-- <md-table-row>
              <md-table-head md-numeric>Uploaded By</md-table-head>
              <md-table-head>Date of Uploading</md-table-head>
              <md-table-head>Email</md-table-head>
              <md-table-head>Name of the Candidate</md-table-head>
              <md-table-head>Email</md-table-head>
              <md-table-head>Position Applied for</md-table-head>
              <md-table-head>See Resume</md-table-head>
              <md-table-head>Status</md-table-head>
              <md-table-head>Status for Invoice</md-table-head>
              <md-table-head>Update Status</md-table-head>
            </md-table-row> -->

            <md-table-row slot="md-table-row" slot-scope="{ item, index }">
              <md-table-cell
                style="font-weight: bolder;"
                v-if="userType == '1'"
                md-label="Uploaded by"
                md-sort-by="email"
                >{{ item.uploaded_by_email }}
              </md-table-cell>
              <md-table-cell
                style="font-weight: bolder;"
                md-label="Date"
                md-sort-by="date"
                >{{ item.updated_at }}
              </md-table-cell>
              <md-table-cell
                style="font-weight: bolder;"
                md-label="Name"
                md-sort-by="name"
                >{{ item.full_name }}
              </md-table-cell>
              <md-table-cell
                style="font-weight: bolder;"
                md-label="Email"
                md-sort-by="email"
                >{{ item.email }}
              </md-table-cell>
              <md-table-cell
                style="font-weight: bolder;"
                md-label="Position"
                md-sort-by="apply_type"
                >{{ item.apply_type }}
              </md-table-cell>

              <md-table-cell
                md-label="Resume"
                md-sort-by="Resume"
                style="font-weight: bolder;"
              >
                <a :href="item.resume" target="_blank" style="color: blue;"
                  >See Resume</a
                >
              </md-table-cell>

              <md-table-cell
                style="font-weight: bolder;"
                md-label="Status"
                md-sort-by="status_text"
              >
                <span
                  style="font-weight: bolder;"
                  :class="[
                    { 'badge badge-warning': item.status_text == 'Processing' },
                    {
                      'badge badge-info':
                        item.status_text == 'Interview schedule',
                    },
                    { 'badge badge-danger': item.status_text == 'Rejected' },
                    { 'badge badge-success': item.status_text == 'Accepted' },
                    { 'badge badge-success': item.status_text == 'Joined' },
                    { 'badge badge-danger': item.status_text == 'Resigned' },
                  ]"
                  >{{ item.status_text }}
                </span>
              </md-table-cell>
              <md-table-cell
                style="font-weight: bolder;"
                md-label="Status of Invoice"
                md-sort-by="Invoice"
              >
                <div v-if="item.status_text == 'Joined'">
                  <span
                    v-if="
                      item.status_text == 'Joined' &&
                        Math.floor(
                          (Date.parse(moment(date).format('MM-DD-YYYY')) -
                            Date.parse(
                              moment(item.updated_at).format('MM-DD-YYYY')
                            )) /
                            86400000
                        ) >= '54'
                    "
                  >
                    <a href="#"
                      >You can now raise your invoice Upload Invoice</a
                    >
                  </span>
                  <span v-else>
                    Your candidate has joined our organization. You can raise
                    often
                    {{
                      54 -
                        Math.floor(
                          (Date.parse(
                            moment(item.current_date).format("MM-DD-YYYY")
                          ) -
                            Date.parse(
                              moment(item.updated_at).format("MM-DD-YYYY")
                            )) /
                            86400000
                        )
                    }}
                    days here. The window will automatically open once you are
                    eligible for verify invoice
                  </span>
                </div>
                <div v-else>
                  <span>Not Applicable</span>
                </div>
              </md-table-cell>
              <md-table-cell
                md-label="Update Status"
                md-sort-by="item.status_text"
                v-if="userType == '0' || userType == '1'"
              >
                <md-field>
                  <md-select
                    @md-selected="onChange(item.id, index)"
                    v-model="item.status_text"
                    name="pages"
                  >
                    <md-option
                      :key="'Processing'"
                      :label="'Processing'"
                      :value="'Processing'"
                    >
                      Processing
                    </md-option>
                    <md-option
                      :key="'Interview schedule'"
                      :label="'Interview schedule'"
                      :value="'Interview schedule'"
                    >
                      Interview schedule
                    </md-option>

                    <md-option
                      :key="'Accepted'"
                      :label="'Accepted'"
                      :value="'Accepted'"
                    >
                      Accepted
                    </md-option>

                    <md-option
                      :key="'Rejected'"
                      :label="'Rejected'"
                      :value="'Rejected'"
                    >
                      Rejected
                    </md-option>

                    <md-option
                      :key="'Joined'"
                      :label="'Joined'"
                      :value="'Joined'"
                    >
                      Joined
                    </md-option>

                    <md-option
                      :key="'Resigned'"
                      :label="'Resigned'"
                      :value="'Resigned'"
                    >
                      Resigned
                    </md-option>
                  </md-select>
                </md-field>
                <!-- {{item.apply_type}} -->
              </md-table-cell>

              <!-- <md-table-cell md-label="Actions">
                        <md-button
                          class="md-icon-button md-raised md-round md-info"
                          @click="checkid(item.id)"
                          style="margin: 0.2rem"
                        >
                          <md-icon>edit</md-icon>
                        </md-button>
                        <md-button
                          class="md-icon-button md-raised md-round md-danger"
                          @click="onProFeature"
                          style="margin: 0.2rem"
                        >
                          <md-icon>delete</md-icon>
                        </md-button>
                        </md-table-cell> -->
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions
          md-alignment="space-between"
          style="font-weight: bolder;"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>

          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "@/components/Pagination";
// import moment from 'moment';
import "moment-timezone";
import Swal from "sweetalert2";

export default {
  components: {
    pagination: Pagination,
  },

  data: () => ({
    userType: "",

    dataall: "",
    table: [],
    item: { status_text: "" },
    footerTable: ["Name", "Email", "Created At", "Actions"],

    query: null,

    sortation: {
      field: "created_at",
      order: "asc",
    },

    pagination: {
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
    },

    total: "",
  }),

  computed: {
    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    updateStatus() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }

      return highBound + this.query;
    },

    doubleAge() {
      return this.query;
    },
  },

  watch: {
    // doubleAge() {
    //       alert(this.query);
    //   },

    to() {
      console.log(process.env);
      axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}candidate?sort=id&order_by=desc&page=${this.pagination.currentPage}&per_page=${this.pagination.perPage}`,
          { params: { search: this.query } }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status_code == 206) {
            this.dataall = "";
            this.total = "0";
          } else {
            this.dataall = response.data.data;
            this.total = response.data.total;
          }
        });
    },
  },

  created() {
    this.userType = localStorage.getItem("data");

    this.planupdate();
    this.getList();
  },

  mounted() {},

  methods: {
    onChange(id, index) {
      if (this.dataall[index].status_text == "Processing") {
        var updateData = "0";
      } else if (this.dataall[index].status_text == "Interview schedule") {
        updateData = "1";
      } else if (this.dataall[index].status_text == "Rejected") {
        updateData = "2";
      } else if (this.dataall[index].status_text == "Accepted") {
        updateData = "3";
      } else if (this.dataall[index].status_text == "Joined") {
        updateData = "4";
      } else if (this.dataall[index].status_text == "Resigned") {
        updateData = "5";
      }

      axios
        .post(`${process.env.VUE_APP_API_BASE_URL}admin/candidate/${id}`, {
          status: updateData,
        })
        .then(() => {
          Swal.fire(
            "Updated successfully!",
            "Record has been Updated " + this.dataall[index].status_text,
            "success"
          );
        });

      //alert(this.dataall[index].status_text);
    },

    async planupdate() {
      await axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}candidate?sort=id&order_by=desc&page=${this.pagination.currentPage}&per_page=${this.pagination.perPage}`
        )
        .then((response) => {
          console.log(response);
          this.dataall = response.data.data;
          this.total = response.data.total;
        });
    },

    getList() {
      this.table = [
        {
          name: "Admin",
          email: "admin@jsonapi.com",
          created_at: "2020-01-01",
        },
      ];
    },

    onProFeature() {
      this.$store.dispatch("alerts/error", "This is a PRO feature.");
    },

    customSort() {
      return false;
    },
  },
};
</script>
<style>
#pro-feature {
  font-weight: bold;
}

.md-table-head-label {
  color: #012a71;
  font-weight: bold;
}
</style>
