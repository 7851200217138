<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card-content style="padding: 0;">
          <div
            style="border: 1px solid #012A71; background-color: #012A71; color: white; font-size: 25px; padding: 20px; border-radius: 15px; font-weight: bolder;"
          >
            Job Vacancies
          </div>
        </md-card-content>
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-100"
            style="padding-left: 0px; padding-right: 7.5px;"
          >
            <form ref="password_form" @submit.prevent="documentUpload">
              <md-card
                style="margin-top: 15px; border: 1px solid white; border-radius: 15px;"
              >
                <md-card-content>
                  <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                      <h4
                        style="color: #012A71; font-weight: bolder; font-size: 25px; margin-bottom: 10px;"
                        class="title"
                        v-if="currentRouteName !== 'VacancyDetails/:id'"
                      >
                        Upload Candidate Details
                      </h4>
                      <div
                        :class="['input-text text-danger', { 'p-0': errmsg }]"
                        v-if="errmsg"
                      >
                        {{ errmsg }}
                      </div>
                      <div
                        :class="['input-text text-info', { 'p-0': succses }]"
                        v-if="succses"
                      >
                        {{ succses }}
                      </div>

                      <md-field class="md-invalid">
                        <label>Full Name</label>
                        <md-input v-model="full_name" type="name" />
                      </md-field>
                      <md-field class="md-invalid">
                        <label>Email</label>
                        <md-input v-model="email" type="email" />
                      </md-field>

                      <md-field class="md-invalid">
                        <label>Contact</label>
                        <md-input v-model="contact" type="name" />
                      </md-field>

                      <md-field class="md-invalid">
                        <label>Work Experiance</label>
                        <md-input v-model="work_exp" type="name" />
                      </md-field>

                      <md-field class="md-invalid">
                        <label>Current CTC</label>
                        <md-input v-model="current_ctc" type="name" />
                      </md-field>
                      <md-field class="md-invalid">
                        <label>Current Location</label>
                        <md-input v-model="current_location" type="name" />
                      </md-field>
                      <md-field class="md-invalid">
                        <label>Address</label>
                        <md-input v-model="address" type="name" />
                      </md-field>

                      <md-field>
                        <label>Select Vacancy</label>
                        <md-select v-model="selected" name="Select Vacancy">
                          <md-option
                            v-for="getDat in dataall"
                            v-bind:key="getDat.id"
                            :label="getDat.title"
                            :value="getDat.title"
                          >
                            {{ getDat.title }}
                          </md-option>
                        </md-select>
                      </md-field>

                      <md-field class="md-invalid">
                        <md-input
                          @change="uploadresume"
                          type="file"
                          accept=".jpeg,.jpg,.png,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        />
                      </md-field>
                    </div>
                  </div>
                </md-card-content>

                <md-card-actions>
                  <md-button
                    type="submit"
                    style="font-weight: bold; font-size: 15px;"
                  >
                    Submit
                  </md-button>
                </md-card-actions>
              </md-card>
            </form>
          </div>

          <div
            class="md-layout-item md-size-50 md-small-size-100"
            style="padding-left: 7.5px; padding-right: 0px;"
          >
            <md-card
              style="margin-top: 15px; border: 1px solid white; border-radius: 15px;"
            >
              <md-card-header style="padding-bottom: 0px;">
                <h4
                  style="color: #012A71; font-weight: bolder; font-size: 25px;"
                  class="title"
                  v-if="currentRouteName !== 'VacancyDetails/:id'"
                >
                  Vacancy
                </h4>

                <md-button
                  @click="backlist"
                  class="p-0"
                  v-if="currentRouteName == 'VacancyDetails/:id'"
                  >Back</md-button
                >
              </md-card-header>
              <md-card-content style="padding-top: 0px;">
                <router-view></router-view>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    registerImg: {
      type: String,
      default: process.env.VUE_APP_BASE_URL + "/img/card-1.jpg",
    },
    applyImg: {
      type: String,
      default: process.env.VUE_APP_BASE_URL + "/img/card-2.jpg",
    },
  },
  data() {
    return {
      //  getDat:{title:''},
      selected: "",
      email: "",
      full_name: "",
      errmsg: "",
      succses: "",
      dataall: "",
      contact: "",
      current_ctc: "",
      address: "",
      current_location: "",
      work_exp: "",
      apply_type: "d",
      type: ["", "info", "success", "warning", "danger"],
      notifications: {
        topCenter: false,
      },
    };
  },

  computed: {
    from() {
      return this.getDat.title;
    },

    currentRouteName() {
      return this.$route.name;
    },
  },

  created() {
    this.planupdate();
  },

  methods: {
    async planupdate() {
      await axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}vacancy/readall?order_by=desc&page=1&per_page=7&is_light=1`
        )
        .then((response) => {
          this.dataall = response.data.data;
        });
    },

    uploadresume(event) {
      this.resume = event.target.files[0];
      this.message = event.target.files[0].name + " File Uploaded";
    },

    documentUpload(event) {
      const docUpload = new FormData();
      if (this.resume) {
        docUpload.append("resume", this.resume, this.resume.name);
      }

      docUpload.append("email", this.email);
      docUpload.append("full_name", this.full_name);
      docUpload.append("contact", this.contact);
      docUpload.append("apply_type", this.selected);
      docUpload.append("work_exp", this.work_exp);
      docUpload.append("current_ctc", this.current_ctc);
      docUpload.append("current_location", this.current_location);
      docUpload.append("address", this.address);

      axios
        .post(`${process.env.VUE_APP_API_BASE_URL}users/candidate`, docUpload)
        .then((response) => {
          this.errmsg = "";
          event.preventDefault(), event.target.reset();
          this.succses = response.data.message;
          this.suuualert = true;
        })
        .catch((error) => {
          this.succses = "";
          this.errmsg = "";
          this.errmsg = error.response.data.message;
        });
    },

    backlist() {
      this.$router.push({ path: "/vacancys/VacancyList/Vacancy" });
    },
    notifyVue(verticalAlign, horizontalAlign) {
      var color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
        timeout: 2500,
        message:
          "Welcome to <b>Vue Material Dashboard Pro</b> - a beautiful admin panel for every web developer.",
        icon: "add_alert",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
      });
    },
  },
};
</script>
<style lang="scss">
.text-center {
  text-align: center;
}

.places-buttons + div .md-button:not(:last-child) {
  margin-right: 5px;
}
</style>
