<template>
  <div class="custom-container">
    <div class="cu-heading">
      <h1 class="lg-text">About <span style="color: blue;">Us</span></h1>
      <h5 class="md-text">About Consultant Panel What We Are !</h5>
    </div>
    <div class="cu-content">
      <div class="first-content">
        <img
          class="content-img"
          src="../../../assets/images/Group 157.svg"
          alt="plasure-meating-with-you"
        />
        <div class="content-para left-content-para">
          <p>
            Welcome to the Consultant Panel, an innovative and indispensable hub
            designed exclusively for HR consultants seeking to enhance and
            streamline their recruitment processes. We recognize the myriad
            challenges faced by HR professionals in the quest for the perfect
            talent, and our mission is to simplify the entire hiring journey. At
            Consultant Panel, we are committed to empowering HR consultants with
            a user-friendly, efficient, and secure platform that revolutionizes
            the recruitment landscape for both consultants and employers a like.
          </p>
        </div>
      </div>

      <div class="first-content" style="flex-direction: row-reverse;">
        <img
          class="content-img"
          src="../../../assets/images/Group 158.svg"
          alt="businessmen-closing-deal-with-hande-shake"
        />
        <div class="content-para right-content-para">
          <p>
            Our platform is built on the foundation of user empowerment,
            offering a range of key features aimed at making the recruitment
            process seamless and effective. One of our standout features is the
            Consultant ID Creation, which allows HR professionals to
            effortlessly generate their consultant ID. This grants them access
            to a personalized dashboard tailored to their unique recruitment
            needs, providing a centralized hub for managing job vacancies and
            candidate profiles.As we continue to innovate and refine our
            offerings, we invite HR professionals to join us on this
            transformative journey towards a future where recruitment is not
            just efficient but truly empowering.
          </p>
        </div>
      </div>

      <div class="first-content">
        <img
          class="content-img"
          src="../../../assets/images/Group 159.svg"
          alt="conversation-walk"
        />
        <div class="content-para left-content-para">
          <p>
            Job Vacancy Management is a cornerstone of our platform, enabling HR
            consultants to post, manage, and update job vacancies with
            unparalleled ease. Our intuitive interface facilitates the inclusion
            of essential details such as comprehensive job descriptions,
            specific qualifications, and application deadlines. This feature
            ensures that consultants can efficiently navigate through the
            recruitment process, saving valuable time and resources.Consultant
            Panel is more than just a platform; it is a strategic partner for HR
            consultants, empowering them to excel in their roles and contribute
            significantly to the success of their organizations.
          </p>
        </div>
      </div>

      <div class="first-content" style="flex-direction: row-reverse;">
        <img
          class="content-img"
          src="../../../assets/images/Group 160.svg"
          alt="smart-business"
        />
        <div class="content-para right-content-para">
          <p>
            To further enhance efficiency, our platform boasts a robust
            Candidate Database. Here, HR consultants can meticulously keep track
            of potential candidates by adding their details to a centralized
            repository. This comprehensive database allows consultants to
            search, filter, and organize candidate profiles based on specific
            criteria, facilitating the discovery of the perfect match for their
            job vacancies. This feature empowers consultants to make informed
            decisions and significantly expedites the candidate selection
            process.
          </p>
        </div>
      </div>

      <div class="first-content">
        <img
          class="content-img"
          src="../../../assets/images/Group 161.svg"
          alt="executive-shoing-project"
        />
        <div class="content-para left-content-para">
          <p>
            At Consultant Panel, we understand the paramount importance of data
            security. Rest assured that our platform prioritizes the
            confidentiality and safety of your information. Our Secure Platform
            ensures that your data is protected, providing a worry-free
            experience for HR professionals. We recognize the sensitivity of the
            information handled during the recruitment process, and our
            commitment to security is unwavering.Experience the Consultant Panel
            advantage and elevate your recruitment process to new heights.
          </p>
        </div>
      </div>

      <div class="first-content" style="flex-direction: row-reverse;">
        <img
          class="content-img"
          src="../../../assets/images/Group 162.svg"
          alt="two-male-collegues"
        />
        <div class="content-para right-content-para">
          <p>
            In addition to these core features, Consultant Panel is continually
            evolving to meet the dynamic needs of HR consultants. We are
            dedicated to staying at the forefront of technological advancements,
            incorporating the latest tools and functionalities to provide an
            unparalleled recruitment experience. Our platform is designed to
            adapt and grow alongside the ever-changing landscape of human
            resources.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginContent",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

* {
  padding: 0;
  margin: 0;
  font-family: "Playfair Display", serif;
}
.custom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.cu-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1.5rem;
}
.lg-text {
  color: black;
  font-size: 3.5em;
  font-weight: bold;
}
.md-text {
  font-size: 1.5em;
  font-weight: bolder;
}

.cu-content {
  width: 85%;
}

.first-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 4rem;
}
.content-img {
  width: 50%;
  height: 23rem;
  border-radius: 1rem;
  position: relative;
  top: -27px;
}
.content-para {
  /* margin: 0rem 4rem; */
  text-align: start;
  color: black;
  font-size: 1.2em;
  word-spacing: 0.2em;
  font-weight: bolder;
}

.left-content-para {
  margin-left: 4rem;
  text-align: start;
}
.right-content-para {
  margin-right: 4rem;
  text-align: right;
}

@media (max-width: 1200px) {
  .cu-content {
    width: 100%;
  }
  .first-content {
    flex-direction: column !important;
  }

  .content-para {
    margin-top: 2rem;
  }

  .left-content-para {
    margin-left: 0;
  }
  .right-content-para {
    margin-right: 0;
  }
  .content-img {
    width: 75%;
  }
}

@media (max-width: 640px) {
  .content-img {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .first-content {
    margin: 3rem 1rem;
  }
}

@media (min-width: 1600px) {
  .cu-content {
    width: 75%;
  }
}

@media (min-width: 1900px) {
  .cu-content {
    width: 65%;
  }
}

@media (min-width: 2200px) {
  .cu-content {
    width: 50%;
  }
}

@media (min-width: 2800px) {
  .cu-content {
    width: 40%;
  }
}
</style>
