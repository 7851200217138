<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card-content style="padding: 0;">
        <div
          style="border: 1px solid #012A71; background-color: #012A71; color: white; font-size: 25px; padding: 20px; border-radius: 15px; font-weight: bolder;"
        >
          List of Consultants
        </div>
      </md-card-content>
      <div class="md-layout">
        <div
          class="md-layout-item md-size-50 md-small-size-100"
          style="padding-left: 0px; padding-right: 7.5px;"
        >
          <form @submit.prevent="documentUpload" ref="form_1">
            <md-card
              style="margin-top: 15px; border: 1px solid white; border-radius: 15px;"
            >
              <md-card-content>
                <h4
                  style="color: #012A71; font-weight: bolder; font-size: 25px; margin-bottom: 10px; margin-left: 15px;"
                  class="title"
                >
                  Upload Consultants
                </h4>
                <div class="md-layout">
                  <label class="md-layout-item md-size-15 md-form-label"
                    >Full Name</label
                  >
                  <div class="md-layout-item">
                    <md-field class="md-invalid">
                      <md-input v-model="full_name" />
                    </md-field>
                  </div>
                </div>

                <div class="md-layout">
                  <label class="md-layout-item md-size-15 md-form-label"
                    >Email</label
                  >
                  <div class="md-layout-item">
                    <md-field class="md-invalid">
                      <md-input v-model="email" />
                    </md-field>
                  </div>
                </div>

                <div class="md-layout">
                  <label class="md-layout-item md-size-15 md-form-label"
                    >Consultant</label
                  >
                  <div class="md-layout-item">
                    <md-field class="md-invalid">
                      <md-input placeholder="Optinal" />
                    </md-field>
                  </div>
                </div>

                <div class="md-layout">
                  <label class="md-layout-item md-size-15 md-form-label"
                    >Mobile</label
                  >
                  <div class="md-layout-item">
                    <md-field class="md-invalid">
                      <md-input placeholder="Optinal" v-model="mobile"  />
                    </md-field>
                  </div>
                </div>
                <br />

                <div class="md-layout">
                  <div class="md-layout-item">
                    <div class="d-flex justify-content-between">
                      <label v-if="userType == '0'">
                        <input
                          type="radio"
                          name="grad"
                          value="1"
                          v-model="user_type"
                        />HR
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="grad"
                          value="2"
                          v-model="user_type"
                        />Consultant
                      </label>
                    </div>
                  </div>
                </div>
              </md-card-content>

              <md-card-actions>
                <md-button
                  type="submit"
                  style="font-weight: bold; font-size: 15px;"
                >
                  Create
                </md-button>
              </md-card-actions>
            </md-card>
          </form>
        </div>

        <div
          class="md-layout-item md-size-50 md-small-size-100"
          style="padding-left: 7.5px; padding-right: 0px;"
        >
          <md-card
            style="margin-top: 15px; border: 1px solid white; border-radius: 15px;"
          >
            <md-card-content style="padding-bottom: 10px">
              <md-table
                :value="userList"
                :md-sort.sync="sortation.field"
                :md-sort-order.sync="sortation.order"
                :md-sort-fn="customSort"
                class="table-striped table-hover"
              >
                <md-table-toolbar>
                  <div class="md-layout  ">
                    <div class="md-layout-item md-size-20">
                      <md-field>
                        <md-select v-model="pagination.perPage" name="pages">
                          <md-option
                            v-for="item in pagination.perPageOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                          >
                            {{ item }}
                          </md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </md-table-toolbar>
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Name" md-sort-by="name">{{
                    item.full_name
                  }}</md-table-cell>
                  <md-table-cell md-label="Email" md-sort-by="email">{{
                    item.email
                  }}</md-table-cell>
                  <md-table-cell md-label="Mobile" md-sort-by="mobile">{{
                    item.mobile
                  }}</md-table-cell>

                  <md-table-cell md-label="Consultant" md-sort-by="consultant"
                    >{{ item.consultant
                    }}{{ item.user_type_text }}</md-table-cell
                  >
                  <md-table-cell
                    md-label="Remove"
                    v-if="userType == '0'"
                    style="text-align: center;"
                  >
                    <a href="#" @click="deleteUser(item)">
                      <i class="material-icons" style="color: #012A71"
                        >delete</i
                      >
                    </a>
                  </md-table-cell>
                </md-table-row>
              </md-table>
              <md-card-actions
                md-alignment="space-between"
                style="display: flex; flex-direction: column; margin-bottom: 0;"
              >
                <div class="">
                  <p class="card-category" style="margin-bottom: 0;">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                  </p>
                </div>
                <pagination
                  class="pagination-no-border pagination-success"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                />
              </md-card-actions>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "@/components/Pagination";

import Swal from "sweetalert2";

// import UserPasswordCard from "@/pages/Dashboard/Examples/UserProfile/EditPasswordCard.vue";
// import UserProfileCard from "@/pages/Dashboard/Examples/UserProfile/UserProfileCard.vue";

export default {
  name: "user-profile-example",

  components: {
    // "user-password-card": UserPasswordCard
    pagination: Pagination,
  },

  data: () => ({
    dataall: "",
    userList: "",
    userType: "",
    succses: "",

    item: [{ status_text: "" }],
    footerTable: ["Name", "Email", "Created At", "Actions"],
    query: null,
    full_name: "",
    email: "",
    password: "",
    confirm_password: "",
    user_type: "2",
    sortation: {
      field: "created_at",
      order: "asc",
    },

    updateSt: {
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
    },

    pagination: {
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
    },

    total: "",
    user: null,
  }),

  computed: {
    sort() {
    
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    updateStatus() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  created() {
    console.log(this.pagination.currentPage);
    this.getList();

    this.userType = localStorage.getItem("data");

    this.planupdate();
  },

  watch: {
    to() {
      axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}user?sort=id&order_by=desc&page=${this.pagination.currentPage}&per_page=${this.pagination.perPage}`
        )
        .then((response) => {
          this.total = response.data.total;
          this.userList = response.data.data;
          console.log(this.userList);
        });
    },
  },

  methods: {
    async deleteUser(item) {
      await axios
        .delete(`${process.env.VUE_APP_API_BASE_URL}user/${item.id}`)
        .then((response) => {
          console.log(response);
          this.$router.go();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async planupdate() {
      await axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}user?page=${this.pagination.currentPage}&per_page=${this.pagination.perPage}`
        )
        .then((response) => {
          this.total = response.data.total;
          this.userList = response.data.data;
          console.log(this.userList);
        });
    },

    documentUpload() {
      const docUpload = new FormData();
      docUpload.append("email", this.email);
      docUpload.append("full_name", this.full_name);
      if(this.mobile && this.mobile !== " "){
        docUpload.append("mobile", this.mobile);
      }
      //  docUpload.append("confirm_password", this.confirm_password);
      docUpload.append("user_type", this.user_type);

      const response = axios
        .post(`${process.env.VUE_APP_API_BASE_URL}user`, docUpload)
        .then((res) => {
          this.errmsg = "";
          this.userList.push(res.data.data);

          Swal.fire("Created Successfully");

          this.$refs.form_1.reset();
          // this.succses = 'Created Successfully'
          this.suuualert = true;
        })
        .catch((error) => {
          this.succses = "";
          this.errmsg = "";
          this.errmsg = error.response.data.message;
          Swal.fire(this.errmsg);

        });
      console.log(response);
    },
    getList() {},

    customSort() {
      return false;
    },
  },
};
</script>
