var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:[
    { 'nav-open': _vm.$sidebar.showSidebar },
    { rtl: _vm.$route.meta.rtlActive }
  ]},[_c('side-bar',[_c('mobile-menu'),_c('template',{slot:"links"},[_c('sidebar-item',{staticClass:"pointer",attrs:{"link":{
          name: 'List of Candidates',
          icon: 'library_books',
          path: '#'
        },"redirectionFunction":_vm.handleRedirection,"path_1":'/candidate/Candidate'}}),(_vm.userType !== '0')?_c('sidebar-item',{staticClass:"pointer",attrs:{"link":{
          name: 'Job Vacancies',
          icon: 'library_books',
          path: '#'
        },"redirectionFunction":_vm.handleRedirection,"path_1":'/vacancys/VacancyList'}}):_vm._e(),(_vm.userType == '0' || _vm.userType == '1')?_c('sidebar-item',{staticClass:"pointer",attrs:{"link":{
          name: 'List of Consultants',
          icon: 'library_books',
          path: '#'
        },"path_1":'/consultantList/consultantList',"redirectionFunction":_vm.handleRedirection}}):_vm._e(),(_vm.userType == '0' || _vm.userType == '1')?_c('sidebar-item',{staticClass:"pointer",attrs:{"link":{
          name: 'Create Vacancy',
          icon: 'library_books',
          path: '#'
        },"redirectionFunction":_vm.handleRedirection,"path_1":'/VacancyCreate/VacancyCreate'}}):_vm._e(),_c('sidebar-item',{staticClass:"pointer",attrs:{"link":{
          name: 'Terms & Conditions',
          icon: 'library_books',
          path: '#'
        },"redirectionFunction":_vm.handleRedirection,"path_1":'/terms/terms'}}),_c('sidebar-item',{staticClass:"pointer",attrs:{"link":{
          name: 'Change your Password',
          icon: 'library_books',
          path: '#'
        },"redirectionFunction":_vm.handleRedirection,"path_1":'/password-change'}}),_c('sidebar-item',{staticClass:"pointer",attrs:{"link":{
          name: 'Payment',
          icon: 'library_books',
          path: '#'
        },"redirectionFunction":_vm.handleRedirection,"path_1":'/TemporaryPlans'}})],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('fixed-plugin',{staticStyle:{"background-color":"white","opacity":"0.01"},attrs:{"color":_vm.sidebarBackground,"colorBg":_vm.sidebarBackgroundColor,"sidebarMini":_vm.sidebarMini,"sidebarImg":_vm.sidebarImg,"image":_vm.sidebarBackgroundImage},on:{"update:color":function($event){_vm.sidebarBackground=$event},"update:colorBg":function($event){_vm.sidebarBackgroundColor=$event},"update:color-bg":function($event){_vm.sidebarBackgroundColor=$event},"update:sidebarMini":function($event){_vm.sidebarMini=$event},"update:sidebar-mini":function($event){_vm.sidebarMini=$event},"update:sidebarImg":function($event){_vm.sidebarImg=$event},"update:sidebar-img":function($event){_vm.sidebarImg=$event},"update:image":function($event){_vm.sidebarBackgroundImage=$event}}}),_c('div',{staticClass:"first-div",class:{ content: !_vm.$route.meta.hideContent }},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1),_c('div',{staticClass:"second-div",class:{ show: _vm.isSubscribed },on:{"click":_vm.showPopUp}})],1),(_vm.showModal)?_c('Plans_Popup_Comp',{ref:"childComponent",attrs:{"redirected_link":_vm.redirect_link,"modal_visible":_vm.showModal},on:{"closeModal":_vm.handleModalClick}}):_vm._e(),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }