<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
      { rtl: $route.meta.rtlActive }
    ]"
  >
    <side-bar>
      <!-- <user-menu></user-menu> -->
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item
          class="pointer"
          :link="{
            name: 'List of Candidates',
            icon: 'library_books',
            path: '#'
          }"
          :redirectionFunction="handleRedirection"
          :path_1="'/candidate/Candidate'"
        />

        <sidebar-item
          class="pointer"
          v-if="userType !== '0'"
          :link="{
            name: 'Job Vacancies',
            icon: 'library_books',
            path: '#'
          }"
          :redirectionFunction="handleRedirection"
          :path_1="'/vacancys/VacancyList'"
        />

        <sidebar-item
          class="pointer"
          v-if="userType == '0' || userType == '1'"
          :link="{
            name: 'List of Consultants',
            icon: 'library_books',
            path: '#'
          }"
          :path_1="'/consultantList/consultantList'"
          :redirectionFunction="handleRedirection"
        />

        <sidebar-item
          class="pointer"
          v-if="userType == '0' || userType == '1'"
          :link="{
            name: 'Create Vacancy',
            icon: 'library_books',
            path: '#'
          }"
          :redirectionFunction="handleRedirection"
          :path_1="'/VacancyCreate/VacancyCreate'"
        />

        <sidebar-item
          class="pointer"
          :link="{
            name: 'Terms & Conditions',
            icon: 'library_books',
            path: '#'
          }"
          :redirectionFunction="handleRedirection"
          :path_1="'/terms/terms'"
        />

        <sidebar-item
          class="pointer"
          :link="{
            name: 'Change your Password',
            icon: 'library_books',
            path: '#'
          }"
          :redirectionFunction="handleRedirection"
          :path_1="'/password-change'"
        />

        <!-- <sidebar-item
          :link="{
            name: 'Payment',
            icon: 'library_books',
            path: '/payment',
          }"
        /> -->

        <sidebar-item
          class="pointer"
          :link="{
            name: 'Payment',
            icon: 'library_books',
            path: '#'
          }"
          :redirectionFunction="handleRedirection"
          :path_1="'/TemporaryPlans'"
        />
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <fixed-plugin
        style="background-color: white; opacity: 0.01;"
        :color.sync="sidebarBackground"
        :colorBg.sync="sidebarBackgroundColor"
        :sidebarMini.sync="sidebarMini"
        :sidebarImg.sync="sidebarImg"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>

      <div :class="{ content: !$route.meta.hideContent }" class="first-div">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <!-- :class="{ 'upper-div': is_subscribed == false }" -->
          <router-view />
        </zoom-center-transition>
        <div
          class="second-div"
          :class="{ show: isSubscribed }"
          @click="showPopUp"
        ></div>
      </div>
      <Plans_Popup_Comp
        v-if="showModal"
        :redirected_link="redirect_link"
        :modal_visible="showModal"
        @closeModal="handleModalClick"
        ref="childComponent"
      />
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
    document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList;
  let isWindows = navigator.platform.startsWith("Win");
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar("sidebar");
    initScrollbar("sidebar-wrapper");
    initScrollbar("main-panel");

    docClasses.add("perfect-scrollbar-on");
  } else {
    docClasses.add("perfect-scrollbar-off");
  }
}

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import FixedPlugin from "../../FixedPlugin.vue";
import Plans_Popup_Comp from "../Plans_Popup/Plans_Popup_Comp.vue";
// import UserMenu from "./Extra/UserMenu.vue";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    FixedPlugin,
    MobileMenu,
    // UserMenu,
    Plans_Popup_Comp
  },
  data() {
    return {
      userType: "",
      sidebarBackgroundColor: "black",
      sidebarBackground: "green",
      sidebarBackgroundImage: "../img/sidebar-2.jpg",
      sidebarMini: true,
      sidebarImg: true,
      image: "../img/laravel-vue.svg",
      showModal: false,
      redirect_link: `${window.location.origin}/TemporaryPlans`,
      isSubscribed: ""
    };
  },
  methods: {
    showPopUp() {
      // console.log("Action from parent!");
      // Check if child reference exists and has the function

      // console.log("Child Component: ", this.$refs.childComponent);
      if (
        this.$refs.childComponent &&
        typeof this.$refs.childComponent.openModal === "function"
      ) {
        this.$refs.childComponent.openModal();
      } else {
        console.log("Function not called!!!");
      }
    },
    handleRedirection(url) {
      // console.log("Link Clicked!!!");
      if (this.isSubscribed == false) {
        // Navigate to the link path (assuming `this.$router` exists)
        // console.log("User Not Subscribed!!");
        // this.showModal = true;
        this.showPopUp();
      } else {
        // console.log("User subscribed");
        // console.log("Routing Path: ", url);
        this.$router.push(`${url}`);
      }
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    }
  },
  updated() {
    reinitScrollbar();
  },
  mounted() {
    this.userType = localStorage.getItem("data");
    reinitScrollbar();

    if (localStorage.getItem("is_subscribed") == "No") {
      this.isSubscribed = false;
      setTimeout(() => {
        this.showModal = true;
      }, 950);
    } else {
      this.isSubscribed = true;
    }
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    }
  }
};
</script>
<style scoped>

@media (min-width: 991px) {
  .sidebar-mini .main-panel {
    width: calc(100% - 260px);
  }
}

.pointer {
  cursor: pointer;
}
.first-div {
  width: 100%;
  height: auto;
  position: relative;
}

.second-div {
  width: 100%;
  height: 90vh;
  position: absolute;
  z-index: 5000;
  top: 0;
  left: 0;
}

.show {
  display: none;
}
</style>
<style lang="scss">
.sidebar,
.off-canvas-sidebar {
  z-index: 1050;
}
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.sidebar[data-background-color="red"]:after,
.off-canvas-sidebar[data-background-color="red"]:after {
  background-color: #012a71;
  opacity: 0.8;
}
</style>
