<template>
  <div>
    <svg
      v-if="!dataall"
      width="20px"
      height="20px"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="spinner"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      class="svg-inline--fa fa-spinner fa-w-16 fa-spin fa-lg"
    >
      <path
        fill="currentColor"
        d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
        class=""
      ></path>
    </svg>

    <h4 v-for="getDat in dataall" v-bind:key="getDat.id">
      <router-link
        :to="{ path: 'VacancyDetails/' + getDat.id }"
        style="color: black;"
        >{{ getDat.title }}</router-link
      >
    </h4>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    registerImg: {
      type: String,
      default: process.env.VUE_APP_BASE_URL + "/img/card-1.jpg",
    },
    applyImg: {
      type: String,
      default: process.env.VUE_APP_BASE_URL + "/img/card-2.jpg",
    },
  },
  data() {
    return {
      dataall: "",
      type: ["", "info", "success", "warning", "danger"],
      notifications: {
        topCenter: false,
      },
    };
  },

  created() {
    this.planupdate();
  },

  methods: {
    async planupdate() {
      await axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}vacancy/readall?order_by=desc&page=1&per_page=7&is_light=1`
        )
        .then((response) => {
          this.dataall = response.data.data;
        });
    },
    notifyVue(verticalAlign, horizontalAlign) {
      var color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
        timeout: 2500,
        message:
          "Welcome to <b>Vue Material Dashboard Pro</b> - a beautiful admin panel for every web developer.",
        icon: "add_alert",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
      });
    },
  },
};
</script>
