<template>
  <div class="container h-100 w-100">
    <div class="row">
      <div class="col">
        <div>
          <div v-if="showPopup" class="popup">
            <div class="popup-content text-center">
              <div class="row w-100">
                <div class="col">
                  <img
                    src="./assets/Scroll--4.svg"
                    style="width: 23rem; margin-top: 6%"
                  />
                </div>
                <div class="col-12 w-100">
                  <h3 style="margin-top: 2%; color: #00a00b !important">
                    Your Payment Was Successful!
                  </h3>
                </div>
                <div class="col-12">
                  <h5 style="margin-top: 1%; color: rgb(65, 65, 65)">
                    Payment reciept sent to your email.
                  </h5>
                </div>
                <div class="col-12 text-center">
                  <button @click="reb()" key="" class="bb" style="">
                    <img src="./assets/arrow.png" /> GET STARTED
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "bootstrap/dist/css/bootstrap.css";
// import router from '@/router'3impor
import axios from "axios";
// import instagram from 'vue-instagram'
export default {
  name: "SuccessPage",
  data() {
    return {
      showPopup: false,
      formData: {
        name: "",
        email: ""
      },
      temporaryPlanId: "",

      // apiurl: "https://uatibconsult.infinitybrains.com/api/"
      apiurl: "https://consultantapi.infinitybrains.com/api/"
    };
  },
  methods: {
    reb() {
      localStorage.removeItem("paymentLogoPlan");
      localStorage.removeItem("paymentAddRemoverPlan");
      localStorage.removeItem("paymentTemporaryPlan");

      localStorage.removeItem("UserEmail");

      localStorage.removeItem("UserFirstName");

      // localStorage.removeItem("UserLastName");

      localStorage.removeItem("UserMobileNo");

      localStorage.removeItem("SelectedPlanId");

      // alert('https://play.google.com/store/apps/details?id=com.ib.ibsalesforce');
      // const data = {
      //   name: "John",
      //   age: 30,
      // };
      // const queryParameters = new URLSearchParams(data).toString();
      // const deepLinkUrl = `ibhrms://open?${queryParameters}`;

      // Try to open the deep link URL
      localStorage.clear();

      this.$router.push({ name: "Login" });
      this.$router.go(0);
      // this.$router.push({ name: "Login" });

      // // Redirect to the Play Store if the deep link fails
      // setTimeout(() => {
      //   const playStoreUrl =
      //     "https://play.google.com/store/apps/details?id=com.ibcrm&hl=en&gl=US";
      //   window.location.href = playStoreUrl;
      // }, 3000);
    },

    async updateTemporaryPaymentStatus() {
      console.log("Update Payment Status Function Called!!!!");
      try {
        const response = await axios.post(
          `${this.apiurl}subscription-users-update-payment/${this.temporaryPlanId}`,
          {
            payment_status: "1"
          }
        );

        if (response.data.status === true) {
          // Payment status successfully updated
          console.log("Payment status updated successfully.");
        } else {
          // Payment status update failed, show an error message
          console.error(
            "Error updating payment status:",
            response.data.message
          );
        }
      } catch (error) {
        // Handle API request error
        console.error("API request error:", error);
      }
    }
  },

  mounted() {
    this.showPopup = true;

    if (localStorage.getItem("paymentTemporaryPlan")) {
      const data = JSON.parse(localStorage.getItem("paymentTemporaryPlan"));

      // console.log("dfsdfsdf = ", data);

      if (data.hashKey && data.UserId) {
        this.temporaryPlanId = data.UserId;
        console.log("Temporary Plan Id: ", this.temporaryPlanId);
        this.updateTemporaryPaymentStatus();
      }
    }
  }
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./assets/bgB.png") fixed center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bb {
  margin-top: 6%;
  font-size: 0.8rem;
  height: 2.5rem;
  width: 11rem;
  background: linear-gradient(180deg, #00abf5 0%, #035e7b 100%);
  border: none;
  border-radius: 8px;
  border-color: transparent;
  color: white;
  margin-bottom: 4%;
  border-color: #00abf5;
}
.popup-content {
  border-radius: 7px;
  background-color: #fff;
  /* padding: 20px; */
  width: 30rem;
  height: 30rem;
  /* max-height: 60rem; */
  max-height: 80%;
  overflow-y: auto;
  background-image: url("./assets/Group2262.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
