<template>
  <div>
    <!-- <div
        class="d-flex align-items-center justify-content-center flex-column w-100"
        style="height: 100vh;"
      > -->
    <!-- <h3>New Pop Up Component</h3> -->

    <button
      type="button"
      class="btn btn-primary my-5"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
      style="display: none;"
      ref="pop_up_btn"
    >
      Click Here To Open Pop Up
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="modal-close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
            >
              <img src="./assets/Group_45025_1.svg" alt="Cross-Img" />
            </button>
          </div>
          <div class="modal-body d-flex align-items-center flex-column gap-1">
            <div>
              <h3 class="text-center modal-body-heading">
                Please Subscribe To Enter <br />
                New Digital World
              </h3>
            </div>
            <div class="modal-text-content">
              <h3 class="text-center mt-2 mb-2"></h3>
              <p class="text-center mb-0 mt-2">
                Don't Miss Out On The Full Experience! <br />Subscribe Now And
                Unlock The Full Potential Of Consultant Panel.
              </p>
            </div>
            <div class="d-flex align-items-center justify-content-center mb-1">
              <img
                src="./assets/Group_45382.png"
                alt="Modal-Center-Img"
                class="modal-body-center-img"
              />
            </div>
            <div
              class="d-flex align-items-center justify-content-center mt-3 mb-3"
            >
              <button
                type="button"
                class="bg-none border-0 px-5 py-2 rounded-1 modal-purchase-btn mt-2 mb-4"
                @click="redirect"
              >
                Explore Plans
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>
  
  <script>
export default {
  name: "New_Pop_Up_Comp",
  props: {
    modal_visible: Boolean,
    redirected_link: String
  },
  data() {
    return {
      showModal: this.modal_visible
    };
  },
  methods: {
    redirect() {
      window.location.href = this.redirected_link;
    },
    openModal() {
      const btn = this.$refs.pop_up_btn;
      btn.click();
    },
    closeModal() {
      this.showModal = false;
      setTimeout(() => {
        this.$emit("closeModal");
      }, 500);
    }
  },
  mounted() {
    const btn = this.$refs.pop_up_btn;

    if (this.modal_visible) {
      btn.click();
    }
  }
};
</script>
  
  <style scoped>
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

* {
  font-family: "Varela Round", sans-serif !important;
  font-style: normal;
}

.modal {
  z-index: 10000;
}

.modal-dialog {
  max-width: fit-content;
}

.modal-content {
  width: auto;
  height: auto;
  background: white;
  border: none;
  border-radius: 18px;
}

.modal-header {
  padding: 0.7rem 0.45rem;
  border-bottom: none;
  justify-content: end;
}

.modal-close-btn {
  background: transparent;
  border: none;
  outline: none;
}

.modal-close-btn img {
  width: 1.5rem;
}

.modal-body {
  padding: 0 1rem;
}

.modal-body-heading {
  font-size: 1.8rem;
  font-weight: 900;
  margin-top: 0;
  line-height: 1.2em;
}

.modal-body-center-img {
  width: 18rem;
}

.modal-text-content {
  width: 90%;
  opacity: 0.85;
}

.modal-text-content h3 {
  font-size: 1.9rem;
  font-weight: 800;
}

.modal-text-content p {
  font-size: 0.9rem;
  line-height: 1em;
  font-weight: 400;
}

.modal-purchase-btn {
  background-color: #0c2d61;
  font-size: large;
  font-weight: 500;
  color: white;
}

.modal-footer {
  border-top: none;
}

@media screen and (max-width: 580px) {
  .modal-dialog {
    width: fit-content;
    margin: 0 auto;
    background-size: 28rem auto;
  }

  .modal-content {
    width: 28rem;
    height: auto;
    margin: auto 0;
  }

  .modal-header {
    padding: 0.6rem 0.45rem;
  }

  .modal-body-heading {
    font-size: 1.2rem;
  }

  .modal-body-center-img {
    width: 14rem;
  }

  .modal-text-content {
    width: 100%;
  }

  .modal-text-content h3 {
    font-size: 1.7rem;
  }

  .modal-text-content p {
    font-size: 0.7rem;
  }

  .modal-purchase-btn {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 480px) {
  .modal-dialog {
    width: fit-content;
    margin: 0 auto;
    background-size: 22rem auto;
  }

  .modal-content {
    width: 22rem;
    height: auto;
    margin: auto 0;
  }

  .modal-body-heading {
    font-size: 1rem;
  }

  .modal-body-center-img {
    width: 11rem;
  }

  .modal-text-content h3 {
    font-size: 1rem;
  }

  .modal-text-content p {
    font-size: 0.7rem;
  }

  .modal-purchase-btn {
    font-size: 0.8rem;
  }

  .br-tag {
    display: none;
  }
}
</style>