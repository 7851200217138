<template>
  <main class="d-flex flex-column min-vh-100 bg-light">
    <div
      class="flex-grow-1 d-flex align-items-center justify-content-center mb-3 overflow-hidden main-container"
    >
      <!-- Left Side Form -->

      <div
        class="d-flex align-items-start justify-content-end custom-form-bg ps-3 pt-5"
      >
        <div class="flex-column custom-left-side-width me-4">
          <div class="mt-3">
            <h3 class="fw-bold mb-0">Payment Details</h3>
            <p class="fw-semibold text-muted sub-title-text-size">
              Effortlessly Handle Your Payment Information
            </p>
          </div>
          <div class="billing-container shadow mb-5 bg-body">
            <div
              class="mb-2 d-flex align-items-center justify-content-between py-1 px-2 billing-header"
            >
              <h3 class="fw-bold fs-6 w-100 text-center mb-0 mt-2 ms-2 pb-2">
                Payment Details
              </h3>
              <img
                src="./assets/Group_45025.svg"
                alt="cross-icon"
                class="mb-2"
              />
            </div>

            <div
              class="billing-content d-flex align-items-center mx-auto flex-column my-4"
            >
              <div class="w-100">
                <h3 class="fw-bold fs-6"><u>Bill Details:</u></h3>
              </div>

              <div
                class="w-100 d-flex align-items-center justify-content-between my-2"
              >
                <h3 class="fw-bold fs-6 text-start">Gross Amount</h3>
                <h3 class="fw-bold fs-6 text-end">₹ {{ amount || "0.00" }}</h3>
              </div>

              <div
                class="w-100 d-flex align-items-center justify-content-between my-2"
              >
                <h3 class="fw-bold fs-6 text-start">C-GST</h3>
                <h3 class="fw-bold fs-6 text-end">₹ {{ cgst || "0.00" }}</h3>
              </div>

              <div
                class="w-100 d-flex align-items-center justify-content-between my-2"
              >
                <h3 class="fw-bold fs-6 text-start">S-GST</h3>
                <h3 class="fw-bold fs-6 text-end">₹ {{ sgst || "0.00" }}</h3>
              </div>

              <div
                class="w-100 d-flex align-items-center justify-content-between my-2"
              >
                <h3 class="fw-bold fs-6 text-start">Payable Amount</h3>
                <h3 class="fw-bold fs-6 text-end">
                  ₹ {{ total_amount || "0.00" }}
                </h3>
              </div>
            </div>
          </div>

          <div class="billing-content custom-button-width">
            <button type="button" class="btn btn-primary w-100" @click="payNow">
              Pay Now
            </button>
          </div>
        </div>
      </div>

      <!-- PayU form -->
      <!-- <form method="POST" class="pl-5 pr-5" id="paymentForm" :action="payuUrl"> -->
      <form
        method="POST"
        class="pl-5 pr-5"
        id="paymentForm"
        :action="payuUrl"
        @submit.prevent="generateHashAndSubmitForm"
      >
        <input type="hidden" name="key" v-model="mkey" size="64" />
        <input type="hidden" name="txnid" v-model="txnid" size="64" />

        <!-- <input type="hidden" name="amount" v-model="amount_pay" size="64" /> -->
        <input type="hidden" name="amount" v-model="total_amount" size="64" />

        <input
          type="hidden"
          name="productinfo"
          v-model="productInfo"
          size="64"
        />

        <input type="hidden" name="firstname" v-model="first_name" size="64" />
        <input
          type="hidden"
          name="service_provider"
          value="payu_paisa"
          size="64"
        />
        <input type="hidden" name="email" v-model="email" size="64" />

        <input type="hidden" name="phone" v-model="mobile_no" size="64" />

        <input type="hidden" name="lastname" v-model="last_name" size="64" />
        <input type="hidden" name="surl" v-model="surl" />
        <input type="hidden" name="furl" v-model="furl" />
        <input type="hidden" name="hash" id="hash" v-model="hash" size="64" />
      </form>

      <!-- Right Side Plan Details -->

      <div
        class="custom-plans-bg d-flex align-items-center justify-content-center"
      >
        <img
          src="./assets/Group_45138.svg"
          alt="right-bg-img"
          class="w-50 img-fluid"
        />
      </div>
    </div>

    <Footer />
  </main>
</template>

<script>
import Footer from "./Footer.vue";
import axios from "axios";
import sha512 from "js-sha512";

import "bootstrap/dist/css/bootstrap.css";

import "bootstrap/dist/js/bootstrap.bundle.min.js";

export default {
  name: "Temporary_plan_billing_Design",
  data() {
    return {
      // selectedSide: "left",

      // apiurl: "https://uatibconsult.infinitybrains.com/api/",
      apiurl: "https://consultantapi.infinitybrains.com/api/",

      addFreeSelected: false,
      uploadLogoSelected: false,

      selectedValue: "1",
      options: [],
      numberOfUser: "",
      userYear: "",
      userPlanAmount: "",

      formError: "",

      userDetails: [],
      userPlanDetails: [],
      userVendor: [],
      userLocation: [],

      isChecked: false,
      //   plan_id: 7,
      plan_id: "",

      cgst: "",
      sgst: "",
      gst: "",
      amount: "",
      total_amount: "",
      first_name: "",
      last_name: "",
      mobile_no: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      uIdUpdPay: "",
      companyName: "",
      companyAddress: "",
      salseManagerCode: "",
      gstNumber: "",
      email: "",
      emailVerified: false,
      message: "",
      // amount_pay: '678.64',
      hash: "",
      txnid: "",
      productInfo: "HRMS Add Free",
      payuUrl: "https://secure.payu.in/_payment",
      mkey: "nxpvv9VZ",
      saltKey: "3oFxUMtWG2",
      // surl: 'https://app.ibdelight.in/success',
      surl: window.location.origin + "/success",
      // furl: 'https://app.ibdelight.in/',
      furl: window.location.origin + "/TemporaryPlans"
      // furl: 'https://ibhrms.com/',
    };
  },
  components: {
    Footer
  },
  mounted() {
    this.makeid();

    if (
      localStorage.getItem("UserEmail") &&
      localStorage.getItem("UserFirstName") &&
      // localStorage.getItem("UserLastName") &&
      localStorage.getItem("UserMobileNo") &&
      localStorage.getItem("SelectedPlanId")
    ) {
      this.plan_id = localStorage.getItem("SelectedPlanId");

      this.first_name = localStorage.getItem("UserFirstName");
      // this.last_name = localStorage.getItem("UserLastName");
      this.email = localStorage.getItem("UserEmail");
      this.mobile_no = localStorage.getItem("UserMobileNo");

      //   console.log("Users Details: ", this.userDetails);
      // console.log("Plan Id: ", this.plan_id);

      this.sendBillingInfo();
    } else {
      // console.log("Going in Else!!!");
    }
  },
  methods: {
    async sendBillingInfo() {
      // console.log("Selected Plan ID: ", this.plan_id);
      // console.log("User Email: ", this.email);
      try {
        const formData = {
          email: this.email,
          plan_id: this.plan_id,
          payment_mode: "0"
        };

        const response = await axios.post(
          `${this.apiurl}subscription-users`,
          formData
        );

        // console.log("API Response From Getting Billing Info:", response.data);

        this.amount = response.data.plan.amount;
        this.cgst = response.data.plan.cgst;
        this.sgst = response.data.plan.sgst;
        this.total_amount = response.data.plan.total_amount;
        this.uIdUpdPay = response.data.plan.id;

        // console.log("Plan ID For Payment: ", this.uIdUpdPay);
        // console.log("Billing info sent successfully.");
      } catch (err) {
        console.log("Error sending billing info:", err);
      }
    },

    // payment integarte
    payNow() {
      this.emailVerified = true;
      if (this.emailVerified) {
        this.generateHashAndSubmitForm();
      }
    },

    generateHashAndSubmitForm() {
      const data =
        this.mkey +
        "|" +
        this.txnid +
        "|" +
        // this.amount_pay +
        this.total_amount +
        "|" +
        this.productInfo +
        "|" +
        this.first_name +
        "|" +
        this.email +
        "|||||||||||";

      this.hash = sha512(data + this.saltKey);

      if (this.hash) {
        localStorage.setItem("hash", this.hash);
        localStorage.setItem("expireSession", "sesion12dgtdb");
      }

      console.log(this.hash);
      console.log(data);

      document.getElementById("hash").value = this.hash;

      // const paymentData = {
      //   hashKey: this.hash,
      //   UserId: this.uIdUpdPay
      // };

      // localStorage.setItem(
      //   "paymentAddRemoverPlan",
      //   JSON.stringify(paymentData)
      // );

      const paymentData = {
        hashKey: this.hash,
        UserId: this.uIdUpdPay
      };

      localStorage.setItem("paymentTemporaryPlan", JSON.stringify(paymentData));

      document.getElementById("paymentForm").submit();
    },

    makeid() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 20; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      this.txnid = text;
    }
  }
};
</script>

<style scoped>
.h3,
h3 {
  margin: 0;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2 !important;
}
.main-container {
  background-color: #fff3e9;
}

button {
  border-color: #0c2d61 !important;
}

button:active {
  background-color: none !important;
}

button:hover {
  opacity: 0.9;
  background-color: inherit !important;
  color: #0c2d61;
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: #2d396c;
  border-color: #0c2d61;
}

.custom-form-bg {
  width: 32%;
  height: 55rem;
  background-color: #ffffff;
}

.custom-left-side-width {
  width: 80%;
}

.custom-left-side-width {
  width: 80%;
}

.sub-title-text-size {
  font-size: 0.75rem;
}

.custom-field-width {
  width: 91%;
}

.billing-container {
  width: 91%;
  height: 19rem;
  border-radius: 12px;
  overflow: hidden;
  margin: 5rem 0;
}

.billing-header {
  background-color: #0c2d61;
}

.billing-header h3 {
  color: #ffffff;
}

.billing-header img {
  cursor: pointer;
}

.billing-content {
  width: 91%;
}

.btn-primary {
  background-color: #0c2d61 !important;
}

.custom-plans-bg {
  width: 70%;
  height: 55rem;
  background-color: #f1f3ff;
}

.custom-plans-bg img {
  width: 742px;
  height: 678px;
}

@media screen and (max-width: 325px) {
}

@media screen and (max-width: 576) {
}

@media screen and (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }
  .custom-form-bg {
    width: 100%;
    height: 42rem;
    z-index: 1;
    justify-content: center !important;
    padding-left: 0 !important;
    padding-bottom: 1.5rem;
  }

  .custom-left-side-width {
    width: 95%;
    margin: 0 auto !important;
  }

  .billing-container {
    width: 100%;
  }

  .billing-content {
    width: 95%;
  }

  .custom-button-width {
    width: 100%;
  }

  .custom-field-width {
    width: 100%;
  }

  .custom-plans-bg {
    width: 100%;
    height: 40rem;
  }

  .custom-plans-bg img {
    width: 75% !important;
  }
}

@media screen and (min-width: 767px) and (max-width: 992px) {
  .custom-form-bg {
    width: 45%;
  }

  .custom-plans-bg {
    width: 55%;
  }
}

@media screen and (min-width: 993px) and (max-width: 1024px) {
  .custom-form-bg {
    width: 40%;
  }

  .custom-plans-bg {
    width: 60%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .custom-form-bg {
    width: 35%;
  }

  .custom-plans-bg {
    width: 65%;
  }
}

@media screen and (min-width: 1440px) {
}
</style>