<template>
  <div class="md-layout text-center login-fix-page">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <form @submit.prevent="resetPasswo">
        <login-card>
          <md-field
            class="form-group md-invalid"
            slot="inputs"
            style="margin-bottom: 28px"
          >
            <md-icon>lock_outline</md-icon>
            <label>Password...</label>
            <md-input v-model="password" type="password" />
            <validation-error :errors="apiValidationErrors.email" />
          </md-field>
          <md-field class="form-group md-invalid" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>Confirm Password...</label>
            <md-input v-model="password_confirmation" type="password" />
          </md-field>
          <md-button
            slot="footer"
            type="submit"
            class="md-simple md-success md-lg"
          >
            Reset password
          </md-button>
        </login-card>
      </form>
    </div>
  </div>
</template>

<script>
import { LoginCard, ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import axios from "axios";

export default {
  components: {
    LoginCard,
    ValidationError,
  },

  mixins: [formMixin],

  data: () => ({
    password: "",
    password_confirmation: "",
  }),

  mounted() {
    var uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    this.token = params.get("token");
    this.email = params.get("email");
  },

  methods: {
    async resetPasswo() {
      try {
        await axios
          .post(`${process.env.VUE_APP_API_BASE_URL}password/reset`, {
            token: this.token,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
          })
          .then((result) => {
            if (result.data.status_code == 200) {
              this.password_confirmation = "";
              this.password = "";
              alert("Password Updated Successful");
              setTimeout(
                function(scope) {
                  scope.$router.push("/");
                },
                2000,
                this
              );
            }
          });
      } catch (e) {
        alert(e.response.data.errors.password);
      }
    },
  },
};
</script>
<style scoped>
.login-fix-page {
  padding-bottom: 7em;
  padding-top: 4em;
}
.swal-wide {
  width: 338px !important;
}
</style>
