<template>
  <div>
    <nav>
      <div class="first-nav-div">
        <div class="logo" @click="$router.push('/')">
          <img
            src="./../../assets/Consltunt panel_web_design/Consultant Panel.png"
            alt=""
          />
        </div>
        <div class="company-name">REDJINNI INDUSTRIES PVT. LTD.</div>
      </div>
      <div class="login-signup">
      
        <button
          class="hover-bg-color"
          @click="$router.push('/the-phoenix-tale')"
          style="background-color: white; color: #0c2d61; border: 1px  solid #0c2d61; border-radius: 7px;"
        >
          The Phoenix Tale
        </button>
        <button
          class="hover-bg-color"
          style="color: #0c2d61; border: 1px  solid #0c2d61; border-radius: 7px"
          @click="$router.push('/ib-talks')"
        >
          IBTalks
        </button>
        <button
          class="hover-bg-color"
          style="color: #0c2d61; border: 1px  solid #0c2d61; border-radius: 7px;"
          @click="$router.push('/all-blogs')"
        >
          Blog
        </button>
        <button
          class="hover-bg-color"
          style="color: #0c2d61; border: 1px  solid #0c2d61; border-radius: 7px;"
          @click="$router.push('/tutorialPage')"
        >
          Tutorials
        </button>
        <button @click="login">Login</button>
        <button @click="signup">Signup</button>
      </div>

      <div class="menubar-icon-div">
        <font-awesome-icon
          :icon="['fas', 'bars']"
          class="menubar-icon"
          @click="openSidebar"
        />
      </div>

      <div class="sidebar-div" :class="{ open: showSidebar }">
        <div class="sidebar-heading-div">
          <h3>Menu</h3>
          <font-awesome-icon
            :icon="['fas', 'xmark']"
            class="x-mark-icon"
            @click="openSidebar"
          />
        </div>
        <div class="sidebar-content">
        
          <router-link to="/the-phoenix-tale">
            The Phoenix Tale
          </router-link>
          <router-link to="/ib-talks">
            IBTalks
          </router-link>
          <router-link to="/all-blogs">
            Blog
          </router-link>
          <router-link to="/tutorialPage">
            Tutorials
          </router-link>
          <button @click="login">Login</button>
          <button @click="signup">Signup</button>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBarComp",
  data() {
    return {
      showSidebar: false
    };
  },

  methods: {
    signup() {
      this.$router.push("/login");
    },
    login() {
      // Add your signup logic here
      this.$router.push("/login");
      localStorage.setItem("signup", "true");
    },
    openSidebar() {
      this.showSidebar = !this.showSidebar;
    }
  }
};
</script>

<style scoped>
.first-nav-div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hover-bg-color {
  background-color: white !important;
}
.hover-bg-color:hover {
  background-color: #0c2d61 !important;
  color: #ffffff !important;
}

nav {
  background-color: white;
  color: #0c2d61;
  padding: 15px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.menubar-icon-div {
  display: none;
  cursor: pointer;
  padding-bottom: 0.5rem;
}

.sidebar-div {
  width: 40%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -100%;
  background-color: #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 10000;
  padding: 2rem 1.6rem;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: 0px 0px 23px #00000045;
  transition: right 0.5s ease-in-out;
}

.open {
  right: 0;
}

.sidebar-heading-div {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  padding-bottom: 0.8rem;
}

.sidebar-heading-div h3 {
  font-size: 1.8rem;
  color: black;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 600;
}

.x-mark-icon {
  font-size: 1.3rem;
  padding-right: 1rem;
  cursor: pointer;
}

.sidebar-content {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.6rem;
  padding-left: 0.8rem;
}

.sidebar-content a {
  width: 7rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  color: #0c2d61;
  padding-left: 0.2rem;
  border: 1px solid #0c2d61;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 7px;
}

.sidebar-content button {
  width: 7rem;
  font-size: 1rem;
  border: none;
  outline: none;
  background: #0c2d61;
  border: none;
  color: white;
  font-weight: 600;
  padding: 10px 0;
  border-radius: 7px;
}

.logo {
  margin-top: -5px;
  cursor: pointer;
}

.company-name {
  font-size: 18px;
  font-weight: bold;
}

.login-signup {
  gap: 10px;
}

.login-signup button {
  padding: 10px 25px;
  margin-left: 10px;
  font-size: 14px;
  background: #0c2d61;
  border: none;
  color: white;
  font-weight: 600;
  box-shadow: 0p 10px 2px gray;
  /* -webkit-animation-fill-mode: ; */
  border-radius: 7px;
}

@media only screen and (max-width: 480px) {
  .sidebar-div {
    width: 85% !important;
  }
}

@media only screen and (max-width: 768px) {
  .company-name {
    display: none;
  }

  .sidebar-div {
    width: 55%;
  }
}

@media only screen and (max-width: 992px) {
  nav {
    align-items: center;
    justify-content: space-between;
    padding: 0.9rem 1.5rem;
    margin-bottom: 0;
  }

  .first-nav-div {
    width: 75% !important;
    align-items: center !important;
    flex-direction: row !important;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  .menubar-icon-div {
    display: flex;
    font-size: 1.3rem;
    padding-right: 0.5rem;
  }

  .login-signup {
    display: none;
  }
}

@media only screen and (min-width: 993px) {
  .sidebar-div {
    right: -100%;
  }
}

@media only screen and (max-width: 1280px) {
  .first-nav-div {
    width: fit-content;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
