<template>
  <div class="width-100" style="min-height: 100vh;">
    <div>
      <Navbar />
    </div>
    <div class="width-100" style="">
      <img
        src="./AssetsIBTalks/bgP.jpg"
        alt="Group44527"
        class="width-100 height-35 object-fit-fill custom-top-img-h"
      />
    </div>

    <div class="absolute top-6 left-12 custom-top-left">
      <div class="width-100">
        <img
          src="./AssetsIBTalks/Group682x.png"
          alt="Group682x"
          class="width-10"
        />
      </div>

      <div class="display-flex flex-column width-100 margin-top-2" style="padding-top: 10px;">
        <h4 class="text-c-w margin-0 padding-0">Welcome To The</h4>
        <h1
          style="margin-top: 50px;"
          class="text-c-w margin-0 padding-0 text-4xl  font-weight-600 margin-y-1 text-size-ibtalks"
        >
          The Phoenix Tale
        </h1>

        <div
          class="display-flex justify-content-start align-items-center gap-1 width-50 custom-width-para-div"
        >
          <div class="width-15" style="border-bottom: 2px solid gray;"></div>
          <h5
            class="text-c-w margin-0 padding-0 text-x-larger para-text-size-bussiness-prodcast "
          >
            The Business Stories
          </h5>
          <div class="width-15" style="border-bottom: 2px solid gray;"></div>
        </div>

        <div
          class="display-flex align-items-center justify-content-center width-50 custom-width-para-div"
        >
          <p class="text-c-w text-larger width-100 para-text-size">
            The Business Stories" is a dynamic platform designed to engage
            listeners in insightful discussions surrounding the world of
            business. From entrepreneurial journeys and industry trends to
            leadership insights and innovative strategies, The Phoenix Tale offers a
            diverse range of topics aimed at inspiring, educating, and
            empowering individuals in the business sphere.
          </p>
        </div>
      </div>
    </div>

    <div class="display-flex justify-content-center flex-column width-100">
      <div
        class="display-flex justify-content-center align-items-center flex-column"
      >
        <h1 style="margin-top: 40px;" class="text-2xl text-c-b font-weight-bold margin-0 padding-0">
          The Phoenix Tale
        </h1>
        <p class="text-1xl text-c-b font-weight-400 margin-0 padding-0">
          (The Business Stories)
        </p>
      </div>

      <div
        class="display-flex justify-content-center align-items-center flex-column margin-top-2 width-100"
      >
        <div
          class="display-flex justify-content-center margin-top-2 width-75 gap-3 custom-flex-col"
        >
          <img
            src="./AssetsIBTalks/3FD93DEA-8999-428E-BD8A-EAC97CFB1F76.png"
            alt="3FD93DEA-8999-428E-BD8A-EAC97CFB1F76"
            class="width-40 custom-first-w-h"
          />

          <p>
            <b> "The Phoenix Tale: The Business Stories" </b>is a dynamic platform designed to
            engage listeners in insightful discussions surrounding the world of
            business. From entrepreneurial journeys and industry trends to
            leadership insights and innovative strategies, The Phoenix Tale offers a
            diverse range of topics aimed at inspiring, educating, and
            empowering individuals in the business sphere. Hosted by seasoned
            professionals and industry experts, each episode delves into
            relevant issues, shares valuable perspectives, and provides
            practical advice for navigating the complexities of modern business
            landscapes. Whether you're a seasoned entrepreneur, aspiring
            business leader, or simply curious about the intricacies of
            commerce, The Phoenix Tale promises to deliver compelling content that sparks
            thought, fosters growth, and drives meaningful conversations in the
            business community. Tune in and join the conversation as we explore
            the ever-evolving world of business together.
          </p>
        </div>

        <div
          class="display-flex justify-content-space-between align-items-center margin-top-2 width-75 gap-3 width-100 custom-flex-star-image"
        >
          <div class="display-flex flex-column width-100">
            <h1
              class="text-3xl font-weight-bold margin-0 padding-0 custom-text-deco"
            >
              Discover Your Favorite Stories
            </h1>
            <p class="text-1xl font-weight-400 margin-0 padding-0">
              Open The Gateway To Infinite Stories Possibilities: Immerse
              Yourself In A Universe Of Diverse And Entertaining Content
            </p>
          </div>

          <img src="./AssetsIBTalks/Group44499.svg" alt="Group44499" />
        </div>

        <div
          class="display-flex flex-column width-75 gap-3 margin-y-4 custom-width-serch-div"
        >
          <div
            class="display-flex justify-content-space-between align-items-center width-100 link-group custom-flex-direction-rev"
          >
            <div class="display-flex gap-6 custom-gap-link">
              <router-link
                to="#"
                class="text-1xl text-c-b font-weight-bold margin-0 padding-0 link-items"
                @mouseover="changeDividerColor(true)"
                @mouseleave="changeDividerColor(false)"
                @click="getAllPodcasts('The Phoenix Tale')"
              >
                All The Phoenix Tale Stories
              </router-link>
              <router-link
                to="#"
                class="text-1xl text-c-b font-weight-bold margin-0 padding-0 link-items"
                @mouseover="changeDividerColor(true)"
                @mouseleave="changeDividerColor(false)"
                @click="getAllPodcasts('Trending Stories')"
              >
                Trending Stories
              </router-link>
              <router-link
                to="#"
                class="text-1xl text-c-b font-weight-bold margin-0 padding-0 link-items"
                @mouseover="changeDividerColor(true)"
                @mouseleave="changeDividerColor(false)"
                @click="getAllPodcasts('Stories Season 1')"
              >
                Season 1
              </router-link>
            </div>

            <q-input
              v-model="search"
              filled
              type="search"
              placeholder="Search Podcast"
              class="custom-search-input"
            >
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
          </div>
          <div class="relative">
            <div class="divider" :style="{ borderBottom: dividerColor }"></div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                  <div
            v-for="podcast in allPodcasts"
            :key="podcast.id"
            style="
              display: flex;
              align-items: center;
              gap: 1rem;
              width: 75%;
              padding: 1.5rem;
              background: #f3f1f4;
              border-radius: 10px;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              margin-bottom: 1.5rem;
            "
          >
            <img
              v-if="podcast.image"
              :src="podcast.image"
              alt="welcome-to-the-IBtalks"
              style="width: 200px; height: 200px;  object-fit: cover;"
            />
            <img
              v-else
              src="./AssetsIBTalks/welcome-to-the-IBtalks.png"
              alt="welcome-to-the-IBtalks"
              style="width: 200px; height: 200px;  object-fit: cover;"
            />
            <div style="display: flex; flex-direction: column; gap: 1rem; text-align: center;">
              <div>
                <h4
                  style="
                    font-size: 1.5rem;
                    color: #333;
                    font-weight: bold;
                    margin: 0.5rem 0;
                  "
                >
                  {{ podcast.title }} : {{ podcast.name }}
                </h4>
                <p
                  style="
                    font-size: 1rem;
                    color: #555;
                    margin: 0.5rem 0;
                    text-align: justify;
                    padding-right: 1rem;
                  "
                >
                  {{ podcast.description }}
                </p>
              </div>

              <div style="margin: 1rem 0; width: 12em;">
                <button
                  @click="redirect(podcast.link)"
                  style="
                    width: 100%;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    padding: 0.5rem;
                    background: #aae5ff;
                    border: 2px solid #00b2ff;
                    border-radius: 5px;
                    cursor: pointer;
                    font-weight: bold;
                    color: #005f99;
                  "
                >
                  WATCH NOW
                  <img
                    src="./AssetsIBTalks/icons8-play-50.png"
                    alt="icons8-play-50"
                    style="width: 15px; height: 15px;"
                  />
                </button>
              </div>
            </div>
          </div>
    </div>
  


    <!-- <div
      class="display-flex justify-content-center align-items-center flex-column width-100"
    >
      <div
        class="display-flex align-items-center gap-4 width-75 custom-flex-col custom-padding content-coustom-width"
        style="background: #F3F1F4 0% 0% no-repeat padding-box;"
      >
        <img
          src="./AssetsIBTalks/welcome-to-the-IBtalks.png"
          alt="welcome-to-the-IBtalks"
          class="width-40 custom-first-w-h"
        />
        <div class="display-flex flex-column gap-4">
          <div class="display-flex flex-column">
            <h4 class="text-2xl text-c-b font-weight-bold margin-0 padding-0">
              Ep 01: Empowering Entrepreneurs To Succeed
            </h4>
            <p class="text-1xl text-c-b font-weight-400 margin-0 padding-0">
              Whether you're a seasoned entrepreneur or just starting your
              journey, "How To Impact On Business" provides invaluable guidance
              and inspiration to help you succeed in your entrepreneurial
              endeavors. From launching a startup to scaling your business, our
              podcast covers the essential steps and strategies for building a
              thriving enterprise.
            </p>
          </div>

          <div class="margin-y-1" style="width: 12em;">
            <button
              class="width-100 display-flex justify-content-space-evenly align-items-center padding-2"
              style="background: #AAE5FF 0% 0% no-repeat padding-box; border: 2px solid #00B2FF; cursor: pointer;"
            >
              WATCH NOW
              <img
                src="./AssetsIBTalks/icons8-play-50.png"
                alt="icons8-play-50"
                width="15"
                height="15"
              />
            </button>
          </div>
        </div>
      </div>

      <div
        class="display-flex align-items-center gap-4 width-75 margin-y-2 custom-flex-col custom-padding content-coustom-width"
        style="background: #F3F1F4 0% 0% no-repeat padding-box;"
      >
        <img
          src="./AssetsIBTalks/welcome-to-the-IBtalks.png"
          alt="welcome-to-the-IBtalks"
          class="width-40 custom-first-w-h"
        />
        <div class="display-flex flex-column gap-4">
          <div class="display-flex flex-column">
            <h4 class="text-2xl text-c-b font-weight-bold margin-0 padding-0">
              Ep 02: Driving Positive Change In The Business World
            </h4>
            <p class="text-1xl text-c-b font-weight-400 margin-0 padding-0">
              Beyond just profitability, businesses have the power to drive
              positive change in society and the world at large. Through
              thought-provoking discussions on topics such as corporate social
              responsibility, sustainability, and ethical leadership, "How To
              Impact On Business" inspires listeners to make a difference and
              leave a lasting legacy through their business endeavors.
            </p>
          </div>

          <div class="margin-y-1" style="width: 12em;">
            <button
              class="width-100 display-flex justify-content-space-evenly align-items-center padding-2"
              style="background: #AAE5FF 0% 0% no-repeat padding-box; border: 2px solid #00B2FF; cursor: pointer;"
            >
              WATCH NOW
              <img
                src="./AssetsIBTalks/icons8-play-50.png"
                alt="icons8-play-50"
                width="15"
                height="15"
              />
            </button>
          </div>
        </div>
      </div>

      <div
        class="display-flex align-items-center gap-4 width-75 margin-y-2 custom-flex-col custom-padding content-coustom-width"
        style="background: #F3F1F4 0% 0% no-repeat padding-box;"
      >
        <img
          src="./AssetsIBTalks/welcome-to-the-IBtalks.png"
          alt="welcome-to-the-IBtalks"
          class="width-40 custom-first-w-h"
        />
        <div class="display-flex flex-column gap-4">
          <div class="display-flex flex-column">
            <h4 class="text-2xl text-c-b font-weight-bold margin-0 padding-0">
              Ep 03: Empowering Entrepreneurs To Succeed
            </h4>
            <p class="text-1xl text-c-b font-weight-400 margin-0 padding-0">
              Whether you're a seasoned entrepreneur or just starting your
              journey, "How To Impact On Business" provides invaluable guidance
              and inspiration to help you succeed in your entrepreneurial
              endeavors. From launching a startup to scaling your business, our
              podcast covers the essential steps and strategies for building a
              thriving enterprise.
            </p>
          </div>

          <div class="margin-y-1" style="width: 12em;">
            <button
              class="width-100 display-flex justify-content-space-evenly align-items-center padding-2"
              style="background: #AAE5FF 0% 0% no-repeat padding-box; border: 2px solid #00B2FF; cursor: pointer;"
            >
              WATCH NOW
              <img
                src="./AssetsIBTalks/icons8-play-50.png"
                alt="icons8-play-50"
                width="15"
                height="15"
              />
            </button>
          </div>
        </div>
      </div>

      <div
        class="display-flex align-items-center gap-4 width-75 margin-y-2 custom-flex-col custom-padding content-coustom-width"
        style="background: #F3F1F4 0% 0% no-repeat padding-box;"
      >
        <img
          src="./AssetsIBTalks/welcome-to-the-IBtalks.png"
          alt="welcome-to-the-IBtalks"
          class="width-40 custom-first-w-h"
        />
        <div class="display-flex flex-column gap-4">
          <div class="display-flex flex-column">
            <h4 class="text-2xl text-c-b font-weight-bold margin-0 padding-0">
              Ep 04: Empowering Strategies For Business Growth
            </h4>
            <p class="text-1xl text-c-b font-weight-400 margin-0 padding-0">
              How To Impact On Business is your ultimate guide to discovering
              empowering strategies that drive tangible growth and success in
              the dynamic world of business. Through insightful discussions,
              expert insights, and practical advice, this podcast equips you
              with the tools and knowledge needed to make a meaningful impact on
              your business endeavors.
            </p>
          </div>

          <div class="margin-y-1" style="width: 12em;">
            <button
              class="width-100 display-flex justify-content-space-evenly align-items-center padding-2"
              style="background: #AAE5FF 0% 0% no-repeat padding-box; border: 2px solid #00B2FF; cursor: pointer;"
            >
              WATCH NOW
              <img
                src="./AssetsIBTalks/icons8-play-50.png"
                alt="icons8-play-50"
                width="15"
                height="15"
              />
            </button>
          </div>
        </div>
      </div>

      <div
        class="display-flex justify-content-center align-items-center gap-4 width-75 margin-y-2 custom-flex-col custom-padding"
      >
        <button
          class="text-c-w padding-2 display-flex align-items-center justify-content-space-evenly"
          style="background: #012A71 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000029; cursor: pointer; width: 8em;"
        >
          All Episode
          <img
            src="./AssetsIBTalks/Icon-awesome-angle-right.svg"
            alt="Icon-awesome-angle-right"
            width="10"
          />
        </button>
      </div>
    </div> -->

    <Footer />
  </div>
</template>

<script>
import Footer from "./Footer.vue";
import "./IBTalk/customLibrary.css";
import Navbar from "./Navbar.vue";
import axios from "axios";
export default {
  name: "IbTalks",
  data() {
    return {
      dividerColor: "2px solid gray",
      widthAll: "",
      widthTp: "",
      widthSe: "",
      base_url: "https://podcast.infinitybrains.com/public/api/", 
      allPodcasts:[]
    };
  },
  components: { Footer, Navbar },

  methods: {
    getAllPodcasts(title) {
      try {
        axios
          .get(`${this.base_url}readall-podcasts?order=desc&title=${title && title != '' ? title : "The Phoenix Tales"}`)
          .then((response) => {
            // console.log("Response of Podcasts: ", response);
            this.allPodcasts = response.data.data;
          });
      } catch (err) {
        console.error(err);
      }
    },
    changeDividerColor(isHovered) {
      if (isHovered) {
        this.dividerColor = "2px solid black";
      } else {
        this.dividerColor = "2px solid gray";
      }
    },
  },
  mounted(){
    this.getAllPodcasts("The Phoenix Tale");
  }
};
</script>

<style scoped>
.link-items {
  text-decoration: none;
  color: #333;
  transition: color 0.3s;
}

.divider {
  width: 100%;
  border-bottom: 2px solid gray;
  transition: border-color 0.3s;
}

@media (min-width: 768px) {
  .row.justify-between {
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .row.justify-between {
    flex-wrap: wrap;
  }

  .custom-width-para-div {
    width: 100% !important;
  }

  .custom-flex-star-image {
    flex-direction: column;
  }

  .custom-flex-star-image img {
    width: 25%;
  }

  .custom-width-serch-div {
    width: 95%;
  }
}

@media (max-width: 1819px) {
  .custom-top-img-h {
    height: 45rem;
  }
}

@media (max-width: 1024px) {
  .custom-top-img-h {
    height: 35rem;
  }

  .custom-top-left {
    top: 6rem;
    left: 5rem;
  }

  .text-size-ibtalks {
    font-size: 4em;
  }

  .custom-width-para-div {
    width: 85%;
  }

  .custom-flex-col {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .custom-first-w-h {
    width: 75%;
    height: 25em;
  }

  .custom-padding {
    padding: 1em;
  }

  .custom-text-deco {
    line-height: 1em;
  }
}

@media (max-width: 992px) {
  .custom-flex-direction-rev {
    flex-direction: column-reverse;
  }

  .custom-search-input {
    width: 90%;
    margin-bottom: 2em;
  }
}

@media (max-width: 650px) {
  .custom-first-w-h {
    width: 100% !important;
    object-fit: inherit;
  }
  .custom-top-left {
    top: 2rem;
  }
}

@media (max-width: 450px) {
  .para-text-size {
    font-size: 1em !important;
  }

  .text-size-ibtalks {
    line-height: 1em;
  }

  .para-text-size-bussiness-prodcast {
    font-size: 2em !important;
  }

  .content-coustom-width {
    width: 100%;
  }

  .custom-gap-link {
    gap: 2rem;
  }
}
</style>
