<template>
  <footer>
    <!-- Contact Details and Quick Links -->
    <div class="footer-content">
      <div class="footer-section">
        <h4 class="footer-heading">Consultant Panel</h4>
        <p>
          Your gateway to expertise. Explore a diverse array of consultants
          ready to guide you to success in various fields.
        </p>
      </div>

      <div class="footer-section" style="width: 100% !important;">
        <h4 class="footer-heading">Contact Details</h4>
        <p style="display: flex; align-items: flex-start;">
          <i class="fas fa-map-marker-alt"></i>
          <span>
            604, Luxuria Business Hub, <br />
            Nr. VR Mall, Vesu, Surat, Gujarat 395007</span
          >
        </p>
        <p style="display: flex; align-items: flex-start;">
          <i class="fas fa-envelope"></i> Contact@infinitybrains.Com
        </p>
        <p style="display: flex; align-items: flex-start;">
          <i class="fas fa-phone"></i> +91 9104198942
        </p>
      </div>

      <div class="footer-section">
        <h4 class="footer-heading">Quick Links</h4>
        <ul>
          <p
            @click="$router.push('/terms-condition')"
            style=" cursor: pointer; text-decoration: underline; "
            class=""
          >
            Terms & Conditions
          </p>
          <p
            @click="$router.push('/policy')"
            style=" cursor: pointer;text-decoration: underline;  "
            class=""
          >
            Privacy Policy
          </p>
        </ul>
      </div>
    </div>

    <!-- Copyright -->
    <div class="footer-copyright">
      <p>&copy; 2024 Copyright. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";

export default {
  name: "FooterComp"
};
</script>

<style>
body {
  background-color: #ffffff !important;
  color: #212529;
}
</style>
<style scoped>
footer {
  background-color: #0c2d61;
  color: white;
  padding: 20px;
  padding-top: 25px;
  /* text-align: left; */
  justify-content: center;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  /* gap: 16rem; */
  align-items: center;
}

.footer-section {
  max-width: 20%;
}

.footer-heading {
  text-align: left;
  margin-bottom: 20px;
  text-shadow: 0px 1px 0px;
  color: white;
}

.footer-copyright {
  margin-top: 20px;
  text-align: center;
}

i {
  background-color: white;
  border-radius: 50%;
  color: #0c2d61;
  padding: 2%;
  margin-right: 15px;
}
@media only screen and (max-width: 900px) {
  .footer-section {
    display: block;
    max-width: 100%;
  }

  .footer-content {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    /* gap: 16rem; */
    align-items: center;
  }
  .footer-heading {
    text-align: left;
  }
}
</style>