<template>
  <div>
    <section style="margin-top: 100px; text-align: center">
      <h2
        style="
          font-size: 2.5rem;
          line-height: normal;
          font-weight: 500;
          margin-bottom: 0;
          color: black;
        "
      >
        Checkout Out Other <span style="color: rgb(66, 66, 155)">Brands</span>
      </h2>
      <h3
        style="
          font-size: 1.5rem;
          font-weight: 600;
          color: rgb(119, 117, 117);
          top: 0;
          margin: 0;
        "
      >
        Discover More Software Solution To enhance Your Digital Experience
      </h3>
    </section>

    <section>
      <div class="banner-brand ">
        <img
          src="./../../assets/Consltunt panel_web_design/Group 44430.png"
          alt=""
        />
      </div>
    </section>

    <footer>
      <!-- Contact Details and Quick Links -->
      <div class="footer-content">
        <div class="footer-section">
          <h4 class="footer-heading">Consultant Panel</h4>
          <p>
            Your gateway to expertise. Explore a diverse array of consultants
            ready to guide you to success in various fields.
          </p>
        </div>

        <div class="footer-section" style="width: 100% !important;">
          <h4 class="footer-heading">Contact Details</h4>
          <p style="display: flex; align-items: flex-start;">
            <i class="fas fa-map-marker-alt"></i>
            <span>
              604, Luxuria Business Hub, <br />
              Nr. VR Mall, Vesu, Surat, Gujarat 395007</span
            >
          </p>
          <p style="display: flex; align-items: flex-start;">
            <i class="fas fa-envelope"></i> Contact@infinitybrains.Com
          </p>
          <p style="display: flex; align-items: flex-start;">
            <i class="fas fa-phone"></i> +91 9104198942
          </p>
        </div>

        <div class="footer-section">
          <h4 class="footer-heading">Quick Links</h4>
          <ul>
            <p
              @click="$router.push('/terms-condition')"
              style=" cursor: pointer; text-decoration: underline; "
              class=""
            >
              Terms & Conditions
            </p>
            <p
              @click="$router.push('/policy')"
              style=" cursor: pointer;text-decoration: underline;  "
              class=""
            >
              Privacy Policy
            </p>
          </ul>
        </div>
      </div>

      <!-- Copyright -->
      <div class="footer-copyright">
        <p>&copy; 2024 Copyright. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterPage",
};
</script>

<style scoped>
footer {
  background-color: #0c2d61;
  color: white;
  padding: 20px;
  /* text-align: left; */
  justify-content: center;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  /* gap: 16rem; */
  align-items: center;
}

.footer-section {
  max-width: 20%;
}

.footer-heading {
  text-align: left;
  margin-bottom: 20px;
  text-shadow: 0px 1px 0px;
  color: white;
}

.footer-copyright {
  margin-top: 20px;
  text-align: center;
}

i {
  background-color: white;
  border-radius: 50%;
  color: #0c2d61;
  padding: 2%;
  margin-right: 15px;
}

.smallWindow {
  display: none;
}

.largeWindow {
  display: block;
}

@media only screen and (max-width: 900px) {
  .logo {
    width: 50% !important;
    margin-top: 107% !important;
    position: absolute;
  }
  .smallWindow {
    display: block;
  }

  .largeWindow {
    display: none;
  }
  .footer-section {
    display: block;
    max-width: 100%;
  }

  .footer-content {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    /* gap: 16rem; */
    align-items: center;
  }
  .footer-heading {
    text-align: left;
  }
  nav {
    flex-direction: column;
    align-items: center;
  }
  .banner-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .login-signup {
    margin-top: 10px;
  }
  .img-des {
    display: flex;
    flex-direction: column;
  }
  .discription-long h2 {
    font-size: 15px !important;
  }

  .banner-company_dis {
    color: black;

    margin: 130px 0px;
    padding: 0% 10px;
  }
  .smallImg {
    display: none;
  }
}
</style>
