<template>
  <div>
    <!-- <div
      class="d-flex align-items-center justify-content-center flex-column w-100"
      style="height: 100vh;"
    > -->
    <!-- <h3>New Pop Up Component</h3> -->

    <button
      type="button"
      class="btn btn-primary my-5"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
      style="display: none;"
      ref="pop_up_btn"
    >
      Click Here To Open Pop Up
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="modal-close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
            >
              <img src="./assets/Group 44528.png" alt="Cross-Img" />
            </button>
          </div>
          <div class="modal-body d-flex align-items-center flex-column gap-1">
            <div>
              <h3 class="text-center modal-body-heading">Free Forever</h3>
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <img
                src="./assets/Group 44532.png"
                alt="Modal-Center-Img"
                class="modal-body-center-img mt-2"
              />
            </div>
            <div class="modal-text-content">
              <h3 class="text-center mt-2 mb-2">Ai-Creatives</h3>
              <p class="text-center mb-0 mt-2">
                Unleash Your Creativity With Festival Creatives! Our
                <br class="br-tag" />
                Product Offers A Wide Variety Of Designs And Templates
                <br class="br-tag" />For All Your Festival Needs.
              </p>
            </div>
            <div class="d-flex align-items-center justify-content-center mt-3">
              <button
                type="button"
                class="bg-none border-0 px-4 py-2 rounded-1 modal-purchase-btn mt-2"
                @click="redirect"
              >
                Get Creatives Free Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "New_Pop_Up_Comp",
  props: {
    modal_visible: Boolean,
    redirected_link: String
  },
  data() {
    return {
      showModal: this.modal_visible
    };
  },
  methods: {
    redirect() {
      window.location.href = this.redirected_link;
    },
    closeModal() {
      this.showModal = false;
      setTimeout(() => {
        this.$emit("closeModal");
      }, 500);
    }
  },
  mounted() {
    // const btn = this.$refs.pop_up_btn;

    // if (this.modal_visible) {
    //   btn.click();
    // }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

* {
  font-family: "Varela Round", sans-serif !important;
  font-style: normal;
}

.modal-dialog {
  max-width: 33rem;
  background: url("./assets/5655049.png") no-repeat fixed center;
  background-size: 33rem 37rem;
}

.modal-content {
  width: 33rem;
  height: 37rem;
  background: transparent;
  border: none;
}

.modal-header {
  padding: 0.7rem 0.45rem;
  border-bottom: none;
  justify-content: end;
}

.modal-close-btn {
  background: transparent;
  border: none;
  outline: none;
}

.modal-close-btn img {
  width: 1.5rem;
}

.modal-body {
  padding: 0 1rem;
}

.modal-body-heading {
  font-size: 4rem;
  font-weight: 900;
  margin-top: 0;
  line-height: 1.2em;
}

.modal-body-center-img {
  width: 15rem;
}

.modal-text-content {
  width: 90%;
  opacity: 0.85;
}

.modal-text-content h3 {
  font-size: 1.9rem;
  font-weight: 800;
}

.modal-text-content p {
  font-size: 0.9rem;
  line-height: 14px;
  font-weight: 400;
}

.modal-purchase-btn {
  background-color: rgb(255, 204, 0);
  font-size: large;
  font-weight: 600;
}

.modal-footer {
  border-top: none;
}

@media screen and (max-width: 580px) {
  .modal-dialog {
    width: fit-content;
    margin: 0 auto;
    background-size: 28rem 35rem;
  }

  .modal-content {
    width: 28rem;
    height: 33rem;
    margin: auto 0;
  }

  .modal-header {
    padding: 0rem 0.45rem;
  }

  .modal-body-heading {
    font-size: 3.5rem;
  }

  .modal-body-center-img {
    width: 14rem;
  }

  .modal-text-content {
    width: 100%;
  }

  .modal-text-content h3 {
    font-size: 1.7rem;
  }

  .modal-text-content p {
    font-size: 0.9rem;
  }

  .modal-purchase-btn {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 480px) {
  .modal-dialog {
    width: 90%;
    margin: 0 auto;
    background-size: 90% 27rem;
  }

  .modal-content {
    width: 100%;
    height: auto;
    margin: auto 0;
  }

  .modal-close-btn img {
    width: 1.1rem;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
  }

  .modal-body-heading {
    font-size: 2.2rem;
  }

  .modal-body-center-img {
    width: 9rem;
  }

  .modal-text-content h3 {
    font-size: 1.1rem;
  }

  .modal-text-content p {
    font-size: 0.6rem;
  }

  .modal-purchase-btn {
    font-size: 0.7rem;
  }

  .br-tag {
    display: none;
  }
}
</style>