<template>
  <ul class="nav nav-mobile-menu">
    <li>
      <md-field>
        <label>Search</label>
        <md-input v-model="search" type="text"></md-input>
      </md-field>
    </li>
    <li>
      <button @click="logout" class="logout">
        <a href="#" class="dropdown-toggle" data-toggle="dropdown">
          <i class="material-icons">dashboard</i>
          <p>Logout</p></a
        >
      </button>
    </li>
  </ul>
</template>
<script>
export default {
  data() {
    return {
      search: null,
      selectedEmployee: null,
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],
    };
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push({ name: "Login" });
      this.$router.go(0);
    },
  },
};
</script>

<style>
.logout {
  all: unset;
}
</style>
