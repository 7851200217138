<template>
  <div class="main full-page" :class="{ 'nav-open': $sidebar.showSidebar }">
    <md-toolbar
      md-elevation="0"
      class="okay md-transparent md-toolbar-absolute padding-0"
    >
      <!-- Removed class md-offset in below class -->
      <div class="firsttool md-toolbar-row">
        <div class="md-toolbar-section-start">
          <img
            src="../../../assets/images/Infinitybrains3.png"
            style="height: 90px; margin: 15px;"
          />
        </div>
        <div class="md-toolbar-section-end">
          <div style="font-weight: bold; color: #012A71; position: absolute;">
            REDJINNI INDUSTRIES PVT. LTD.
          </div>
          <md-button
            class="md-just-icon md-simple md-round md-toolbar-toggle"
            :class="{ toggled: $sidebar.showSidebar }"
            @click="toggleSidebar"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </md-button>

          <div
            class="md-collapse"
            :class="{ 'off-canvas-sidebar': responsive }"
          ></div>
          <!-- <img
            class=""
            src="../../../assets/images/redjinni.png"
            style="height: 70px; width: 70px; margin-top: 10px; margin-left: 10px;"
          />
          <img
            class=""
            src="../../../assets/images/Garbajockey.png"
            style="height: 70px; width: 70px; margin-top: 10px; margin-left: 10px;"
          />
          <img
            class=""
            src="../../../assets/images/RestroworldRound.png"
            style="height: 70px; width: 70px; margin-top: 10px; margin-left: 10px;"
          />
          <img
            class=""
            src="../../../assets/images/Redprix.png"
            style="height: 70px; width: 70px; margin-top: 10px; margin-left: 5px;"
          /> -->
        </div>
      </div>
    </md-toolbar>
    <div
      class="secondmain wrapper wrapper-full-page"
      @click="toggleSidebarPage"
    >
      <notifications />
      <div
        class="page-header header-filter"
        :class="setPageClass"
        filter-color="black"
        :style="setBgImage"
      >
        <div class="container md-offset">
          <zoom-center-transition
            :duration="pageTransitionDuration"
            mode="out-in"
          >
            <router-view></router-view>
          </zoom-center-transition>
        </div>
        <md-toolbar
          md-elevation="0"
          class="thirdmain md-transparent md-toolbar-absolute"
        >
          <!-- Removed class md-offset in below class -->
          <div class="bottomLogo md-toolbar-row">
            <!-- <div class="md-toolbar-section-end"></div> -->
            <div class="md-toolbar-section-start">
              <div
                class="md-collapse"
                :class="{ 'off-canvas-sidebar': responsive }"
              ></div>
              <div style="margin-right: 10px;">
                <img
                  class=""
                  src="../../../assets/images/Infinitybrains2.png"
                  style="height: 70px; width: 70px; margin-top: 10px; margin-bottom: 10px;"
                />
                <a
                  href="https://infinitybrains.com"
                  style="color: white; font-weight: bold; text-decoration: underline; "
                  >Infinitybrains</a
                >
              </div>
              <div style="margin-right: 20px;">
                <img
                  class=""
                  src="../../../assets/images/RW.png"
                  style="height: 70px; width: 70px; margin-top: 10px; margin-bottom: 10px;"
                /><br />
                <a
                  href="https://www.restroworld.com/"
                  class="link"
                  style="color: white; font-weight: bold; text-decoration: underline;"
                  >Restroworld</a
                >
              </div>
              <div style="margin-right: 10px; ">
                <img
                  class=""
                  src="../../../assets/images/RP.png"
                  style="height: 70px; width: 70px; margin-top: 10px; margin-bottom: 10px;"
                />
                <a
                  href="https://redprix.com"
                  class="link"
                  style="color: white; font-weight: bold; text-decoration: underline;"
                  >Redprix</a
                >
              </div>
              <div style="margin-right: 10px;">
                <img
                  class=""
                  src="../../../assets/images/RJ.png"
                  style="height: 70px; width: 70px; margin-top: 10px; margin-bottom: 10px;"
                />
                <a
                  href="https://redjinni.com"
                  class="link"
                  style="color: white; font-weight: bold; text-decoration: underline;"
                  >Redjinni</a
                >
              </div>
              <div style="margin-right: 20px;">
                <img
                  class=""
                  src="../../../assets/images/Garbajockey.png"
                  style="height: 70px; width: 70px; margin-top: 10px; margin-bottom: 10px;"
                />
                <a
                  href="https://garbajockey.com"
                  class="link"
                  style="color: white; font-weight: bold; text-decoration: underline; "
                  >Garbajockey</a
                >
              </div>
            </div>
          </div>
        </md-toolbar>
      </div>
    </div>

    <div
      style="display: flex; justify-content: center; align-items: center; width: 100%;"
    >
      <LoginContent />
    </div>
  </div>
</template>

<script>
import LoginContent from "./LoginContent.vue";

// import InfinityBrains from "../../../assets/images/Infinitybrains.png";

export default {
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  inject: {
    autoClose: {
      default: true,
    },
  },
  data() {
    return {
      responsive: false,
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 300,
      year: new Date().getFullYear(),
    };
  },
  computed: {
    setBgImage() {
      let images = {
        Login: "../img/Consultant.jpg",
        PasswordReset: "../img/Consultant.jpg",
        Register: "../img/register.jpg",
      };
      return {
        backgroundImage: `url(${images[this.$route.name]})`,
      };
    },
    setPageClass() {
      return `${this.$route.name}-page`.toLowerCase();
    },
  },
  methods: {
    toggleSidebarPage() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    linkClick() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    this.closeMenu();
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  components: { LoginContent },
};
</script>

<style lang="scss" scoped>
$scaleSize: 0.1;
$zoomOutStart: 0.7;
$zoomOutEnd: 0.46;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}
.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}
@keyframes zoomOut8 {
  from {
    opacity: 1;
    transform: scale3d($zoomOutStart, $zoomOutStart, $zoomOutStart);
  }
  to {
    opacity: 0;
    transform: scale3d($zoomOutEnd, $zoomOutEnd, $zoomOutEnd);
  }
}
.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}

.main .secondmain .thirdmain .bottomLogo {
  position: relative;
  // top: 18rem;
  bottom: 0;
  right: calc(50% - 300px);
  text-align: center;
  padding: 20px;
}

.md-toolbar.md-toolbar-absolute {
  position: relative;
}

.page-header {
  flex-direction: column;
}
.main .okay .firsttool {
  background-color: rgba($color: white, $alpha: 0.8);
}

.main .okay {
  padding: 0;
}
</style>
