<template>
  <div class="md-layout text-center login-fix-page">
    <div class="md-layout-item md-size-100"></div>

    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <form @submit.prevent="login" v-if="logincard">
        <login-card>
          <!-- <h4 slot="title" class="title">Log in</h4> -->
          <h4 slot="title" class="title">Login</h4>
          <md-field
            class="form-group md-invalid"
            slot="inputs"
            style="margin-bottom: 28px;"
          >
            <md-icon>email</md-icon>
            <label>Email Address</label>
            <md-input v-model="email" type="email" />
            <validation-error :errors="apiValidationErrors.email" />
          </md-field>
          <md-field
            class="form-group md-invalid"
            slot="inputs"
            style="margin-bottom: 14px"
          >
            <md-icon>lock_outline</md-icon>
            <label>Password</label>
            <md-input v-model="password" type="password" />
          </md-field>
          <md-button
            style="font-weight: bold; border: 3px solid #012A71; padding: 0 90px; border-radius: 10px 10px;"
            slot="inputs"
            type="submit"
            class="md-simple md-primary md-md"
          >
            Login
          </md-button>
          <a @click="rester" slot="footer" href="#" class="forgotpass  bg-light"
            ><u>Forgot Password?</u>
          </a>

          <a
            @click="alreadyAccount()"
            slot="footer"
            href="#"
            class=" mr-5 bg-light"
            ><u style="margin-left: 30%;"> Signup</u></a
          >
        </login-card>
      </form>
      <!-- add form of Signup  -->
      <form @submit.prevent="signup" v-else>
        <signup-card>
          <h4 slot="title" class="title">Signup</h4>
          <md-field
            class="form-group md-invalid"
            slot="signup"
            style="margin-bottom: 14px"
          >
            <md-icon>insert_emoticon</md-icon>
            <label>Name</label>
            <md-input v-model="name" type="text" />
          </md-field>

          <md-field
            class="form-group md-invalid"
            slot="signup"
            style="margin-bottom: 14px"
          >
            <md-icon>contacts</md-icon>
            <label>Mobile Number</label>
            <md-input v-model="mobile" type="number" />
          </md-field>

          <md-field
            class="form-group md-invalid"
            slot="signup"
            style="margin-bottom: 14px"
          >
            <md-icon>email</md-icon>
            <label>Email Address</label>
            <md-input v-model="emails" type="email" />
          </md-field>

          <md-field
            class="form-group md-invalid"
            slot="signup"
            style="margin-bottom: 14px"
          >
            <md-icon>badge</md-icon>
            <label>Sales Manager Code</label>
            <md-input v-model="sales_manager_code" type="text" />
          </md-field>

          <md-field
            class="form-group md-invalid"
            slot="signup"
            style="margin-bottom: 14px"
          >
            <md-icon>lock_outline</md-icon>
            <label>Password</label>
            <md-input v-model="passwords" type="password" />
          </md-field>

          <md-button
            style="font-weight: bold; border: 3px solid #012A71; padding: 0 90px; width: 100%; border-radius: 10px 10px;"
            slot="signup"
            type="submit"
            class="md-simple md-primary md-md"
          >
            Create Account
          </md-button>

          <a
            @click="alreadyAccount()"
            slot="footer"
            href="#"
            class="forgotpass bg-light"
            ><u>Already have Account ?</u></a
          >
        </signup-card>
      </form>
    </div>
  </div>
</template>

<script>
import { LoginCard, SignupCard, ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: {
    LoginCard,
    SignupCard,
    ValidationError
  },

  mixins: [formMixin],

  data: () => ({
    email: "",
    password: "",
    sales_manager_code: "",
    logincard: localStorage.getItem("signup")
  }),
  mounted() {
    if (localStorage.getItem("vue-authenticate.vueauth_access_token")) {
      this.$router.push(`/login`);
    }

    if (!localStorage.getItem("signup")) {
      localStorage.setItem("signup", false);
    }
  },
  methods: {
    alreadyAccount() {
      if (this.logincard) {
        this.logincard = false;
      } else {
        this.logincard = true;
      }
    },
    rester() {
      Swal.fire({
        title: "Password Reset",
        customClass: "sweetalert-lg",
        html: `<input type="email" id="login" class="swal2-input" placeholder="Email Account">`,
        confirmButtonText: "Reset",

        focusConfirm: false,
        preConfirm: () => {
          const login = Swal.getPopup().querySelector("#login").value;
          if (!login) {
            Swal.showValidationMessage(`The selected email is invalid `);
          }
          return { login: login };
        }
      }).then(result => {
        if (
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(result.value.login)
        ) {
          axios.post(`${process.env.VUE_APP_API_BASE_URL}forgot-password`, {
            email: result.value.login
          });
          setTimeout(
            () => alert("We have emailed your password reset link!"),
            1000
          );
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "The selected email is invalid!"
          });
        }
      });
      // });
      // setTimeout(() => alert('We have emailed your password reset link!'), 1000);
    },

    //popup for otp verify

    otp() {
      Swal.fire({
        title: "Enter Otp Here",
        customClass: "sweetalert-lg",
        html: `<input type="number" id="otp" class="swal2-input" placeholder="XXXXXX">`,
        confirmButtonText: "SUBMIT",
        focusConfirm: false,
        preConfirm: () => {
          const otp = Swal.getPopup().querySelector("#otp").value;

          return { otp: otp };
        }
      }).then(result => {
        axios
          .post(`${process.env.VUE_APP_API_BASE_URL}verify-email`, {
            email: this.emails,
            otp: result.value.otp
          })
          .then(() => {
            this.logincard = true;
            this.$router.push("/candidate/Candidate");
          });
      });
      // });
      // setTimeout(() => alert('We have emailed your password reset link!'), 1000);
    },

    //popup for otp verify

    async login() {
      const user = {
        // data: {
        //   type: "token",
        //   attributes: {
        email: this.email,
        password: this.password
        //   }
        // }
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json"
        }
      };

      try {
        await this.$store.dispatch("login", { user, requestOptions });
        this.$router.push("/candidate/Candidate");
      } catch (e) {
        await this.$store.dispatch("alerts/error", "Invalid credentials!");
        this.setApiValidation(e.response.data.errors);
      }
    },

    async signup() {
      const user = {
        // data: {
        //   type: "token",
        //   attributes: {
        email: this.emails,
        password: this.passwords,
        full_name: this.name,
        mobile: this.mobile,
        sales_manager_code: this.sales_manager_code,
        is_freetrial: "1"

        //   }
        // }
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json"
        }
      };

      try {
        await this.$store
          .dispatch("register", { user, requestOptions })
          .then(() => {
            this.otp();
          });
      } catch (e) {
        await this.$store.dispatch("alerts/error", "Invalid credentials!");
        this.setApiValidation(e.response.data.errors);
      }
    }
  }
};
</script>

<style scoped>
.login-fix-page {
  padding-bottom: 5em;
  padding-top: 6em;
}
.swal-wide {
  width: 338px !important;
}
.forgotpass u {
  color: #012a71;
  text-decoration: none;
}
</style>
