<template>
  <router-view class="custom-page-animation"></router-view>
</template>

<script>
export default {};
</script>

<style>
.custom-page-animation {
  animation: fadeIn;
  animation-duration: 1.2s;
}
</style>
