<template>
  <div
    class="sidebar"
    data-color=""
    data-image="/Users/artic/Documents/IB-Consultancy2/dist/img/#012A71.png"
    data-background-color="red"
    :style="sidebarStyle"
  >
    <div class="logo" style="background-color: white; padding: 10px;">
      <img
        style="height: 70px; margin-left: 22px;"
        src="../../assets/images/Infinitybrains3.png"
        class="simple-text logo-normal"
        alt=""
      />
      <!-- <a href="#" class="simple-text logo-mini">
        <div style="padding: 4px 0;">
          IB
        </div>
      </a>
      <a href="#" class="simple-text logo-normal">
        <template v-if="$route.meta.rtlActive">{{ rtlTitle }}</template>
        <template v-else>{{ title }}</template>
      </a> -->
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <md-list class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
// import img from '../../assets/images/InfyBg.jpg'
export default {
  name: "sidebar",
  props: {
    title: {
      type: String,
      default: "Inifnitybrains"
    },
    rtlTitle: {
      type: String,
      default: "توقيت الإبداعية"
    },
    activeColor: {
      type: String,
      default: "green",
      validator: value => {
        let acceptedValues = [
          "",
          "purple",
          "azure",
          "green",
          "orange",
          "danger",
          "rose"
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    backgroundImage: {
      type: String,
      default: `${process.env.VUE_APP_BASE_URL}/img/sidebar-2.jpg`
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: value => {
        let acceptedValues = ["", "black", "white", "red"];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    logo: {
      type: String,
      default: `${process.env.VUE_APP_BASE_URL}/img/vue-logo.png`
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.$sidebar.toggleMinimize();
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    }
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`
      };
    }
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }
};
</script>
<style>
.sidebar[data-background-color="red"]:after,
.off-canvas-sidebar[data-background-color="red"]:after {
  background-color: #012a71;
  opacity: 0.8;
}

@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

@media (min-width: 991px) {
  .sidebar-mini .sidebar {
    width: 260px !important;
  }

  .sidebar-mini .sidebar .sidebar-wrapper {
    width: 260px !important;
  }
}
</style>
