<template>
  <div class="md-layout md-gutter">
    <div
      class="md-layout-item md-size-66 md-small-size-100"
      style="padding-right: 0px;"
    >
      <div class="md-layout-item md-size-100" style="padding-right: 0px;">
        <md-card-content style="padding: 0;">
          <div
            style="border: 1px solid #012A71; background-color: #012A71; color: white; font-size: 25px; padding: 20px; border-radius: 15px; font-weight: bolder;"
          >
            Change Password
          </div>
        </md-card-content>
        <md-card
          style="margin-top: 15px; border: 1px solid white; border-radius: 15px;"
        >
          <md-card-content style="padding: 0;">
            <form ref="password_form" @submit.prevent="changePassword">
              <md-card-content>
                <md-field class="md-invalid">
                  <label>Old Password</label>
                  <md-input v-model="old_password" type="password" required />
                </md-field>

                <md-field class="md-invalid">
                  <label>New Password</label>
                  <md-input
                    v-model="new_password"
                    type="password"
                    required
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                  />
                </md-field>
                <md-field class="md-invalid">
                  <label>Confirm New Password</label>
                  <md-input
                    v-model="confirm_password"
                    type="password"
                    required
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                  />
                </md-field>
              </md-card-content>

              <md-card-actions>
                <md-button
                  type="submit"
                  style="font-weight: bold; font-size: 15px;"
                >
                  Change Password
                </md-button>
              </md-card-actions>
            </form>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div
      class="md-layout-item md-size-33 md-small-size-100"
      style="padding-top: 20px;"
    >
      <md-card class="md-card-profile">
        <div class="md-card-avatar">
          <img class="img" src="../../../assets/images/Infinitybrains2.png" />
        </div>
        <md-card-content>
          <p class="card-description" style="font-weight: bold;">
            “We, at Infinity Brains, endeavor to offer you the best solutions in
            order to acquire your maximum satisfaction. We are experts in
            offering effective Information Technology solutions. We have
            experience in solving problems like web or software development etc.
            Our experts handle your assigned projects prudently as well. We
            strive for perfectionism in this era of technological advancement,
            by providing best plus affordable IT services to numerous large as
            well as medium entrepreneurs. Everyday we become better & learn the
            new latest technologies in the market. Infinity Brains is a place
            built by dreamers to make your dreams come true!”
          </p>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "user-profile-example",

  props: {
    cardUserImage: {
      type: String,
      default: "../../../assets/images/Infinitybrains2.png",
    },
    buttonColor: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    old_password: "",
    new_password: "",
    confirm_password: "",
  }),

  created() {
    this.getProfile();
  },

  methods: {
    async changePassword() {
      axios
        .post(`${process.env.VUE_APP_API_BASE_URL}change-password`, {
          old_password: this.old_password,
          new_password: this.new_password,
          confirm_password: this.confirm_password,
        })
        .then(() => {
          this.$refs.password_form.reset();

          alert("Password is changes successfully");
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    },
  },
};
</script>

<style>
.md-card-actions > .md-button > .md-ripple {
  background-color: #012a71;
}
</style>
