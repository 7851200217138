<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card-content style="padding: 0;">
        <div
          style="border: 1px solid #012A71; background-color: #012A71; color: white; font-size: 25px; padding: 20px; border-radius: 15px; font-weight: bolder;"
        >
          Create Vacancies
        </div>
      </md-card-content>
      <div class="md-layout">
        <div
          class="md-layout-item md-size-50 md-small-size-100"
          style="padding-left: 0px; padding-right: 7.5px;"
        >
          <form @submit.prevent="changePassword" ref="form_1">
            <md-card
              style="margin-top: 15px; border: 1px solid white; border-radius: 15px;"
            >
              <md-card-content>
                <div class="md-layout">
                  <div class="md-layout-item md-size-100">
                    <md-field class="md-invalid">
                      <label>Title</label>
                      <md-input v-model="title" type="text" />
                    </md-field>

                    <label>Job Description</label>

                    <client-only>
                      <VueEditor v-model="description" />
                    </client-only>
                  </div>
                </div>
              </md-card-content>
              <md-card-actions>
                <md-button
                  @click="getProfile"
                  type="submit"
                  style="font-weight: bold; font-size: 15px; margin-right: 20px;"
                >
                  Create
                </md-button>
              </md-card-actions>
            </md-card>
          </form>
        </div>

        <div
          class="md-layout-item md-size-50 md-small-size-100"
          style="padding-left: 7.5px; padding-right: 0px;"
        >
          <md-card
            style="margin-top: 15px; border: 1px solid white; border-radius: 15px;"
          >
            <md-card-content style="padding-bottom: 10px">
              <ul
                class="list-group"
                style="padding-left: 20px; font-size: 20px;"
              >
                <li
                  style="padding: 0 15px 15px 15px;"
                  class="list-group-item"
                  v-for="(getDat, index) in vacnsyData"
                  v-bind:key="getDat.id"
                >
                  {{ getDat.title }}
                  <md-button
                    class="md-icon-button md-raised md-round "
                    @click="dataDelte(getDat.id, index)"
                    style="margin: 0.2rem"
                  >
                    <md-icon>delete</md-icon>
                  </md-button>
                </li>
              </ul>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="md-layout md-gutter">
    <div class="md-layout-item md-size-65 md-small-size-100">
      <div class="md-layout-item md-size-100">
        <form @submit.prevent="changePassword" ref="form_1">
          <md-card>
            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <md-field class="md-invalid">
                    <label>Title</label>
                    <md-input v-model="title" type="text" />
                  </md-field>

                  <label>Job Description</label>

                  <client-only>
                    <VueEditor v-model="description" />
                  </client-only>
                </div>
              </div>
            </md-card-content>

            <md-card-actions>
              <md-button @click="getProfile" type="submit">
                Create
              </md-button>
            </md-card-actions>
          </md-card>
        </form>
      </div>
    </div>

    <div class="md-layout-item md-size-35 md-small-size-100">
      <md-card>
        <ul class="list-group">
          <li
            class="list-group-item"
            v-for="(getDat, index) in vacnsyData"
            v-bind:key="getDat.id"
          >
            {{ getDat.title }}
            <md-button
              class="md-icon-button md-raised md-round md-danger"
              @click="dataDelte(getDat.id, index)"
              style="margin: 0.2rem"
            >
              <md-icon>delete</md-icon>
            </md-button>
          </li>
        </ul>
      </md-card>
    </div>
  </div> -->
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";

export default {
  name: "user-profile-example",

  components: {
    VueEditor,
  },

  data: () => ({
    user: null,
    description: "",
    title: "",
    vacnsyData: "",
  }),

  created() {
    this.vacancyList();
  },

  methods: {
    dataDelte(id, index) {
      axios
        .delete(`${process.env.VUE_APP_API_BASE_URL}vacancy/` + id)
        .then((res) => {
          this.vacnsyData.splice(index, 1);

          this.$notify({
            type: "danger",
            message: res.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.response.data.message,
          });
        });
    },

    async vacancyList() {
      await axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}vacancy/readall?order_by=desc&page=1&per_page=7&is_light=1`
        )
        .then((response) => {
          this.vacnsyData = response.data.data;
        });
    },

    async getProfile() {
      await axios
        .post(`${process.env.VUE_APP_API_BASE_URL}vacancy`, {
          title: this.title,
          description: this.description,
        })
        .then((response) => {
          this.description = "";
          this.$refs.form_1.reset();
          alert(response.data.message);
        })
        .catch((e) => {
          alert(e.response.data.message);
        });
    },
  },
};
</script>

<style>
.list-group-item > .md-button > .md-ripple {
  background-color: #012a71;
}
</style>
