<template>
  <main class="d-flex flex-column min-vh-100 bg-light">
    <div
      class="flex-grow-1 d-flex align-items-center justify-content-center overflow-hidden main-container"
    >
      <!-- Left Side Form -->

      <div
        class="d-flex align-items-center justify-content-end custom-form-bg pb-5"
      >
        <div class="flex-column custom-left-side-width me-4">
          <div class="mt-4">
            <h3 class="fw-bold mb-0">Help Us Know You Better</h3>
            <p class="fw-semibold text-muted sub-title-text-size">
              Everything You Need, Just A Few Clicks Away !
            </p>
          </div>
          <div class="doted-line-div"></div>

          <p class="text-danger text-center mt-2 fs-6">{{ formError }}</p>

          <div class="mt-3">
            <!-- Email Field  -->
            <div class="d-flex flex-column my-2 custom-field-width">
              <label
                for="email"
                class="text-start custom-label-size text-muted fw-semibold mt-1"
                >Email Address</label
              >
              <div
                class="d-flex align-items-center w-100 custom-email-input-style position-relative"
              >
                <input
                  type="text"
                  id="email"
                  class="form-control py-1 bg-light"
                  v-model="email"
                  :disabled="emailVerified"
                  @keyup.enter="getEmail"
                />
                <button
                  class="btn btn-primary button-color py-1"
                  @click="getEmail"
                  :disabled="emailVerified || !email"
                >
                  Check
                </button>
              </div>
            </div>

            <!-- First Name/Last Name Field  -->

            <div
              class="d-flex align-items-center justify-content-between custom-field-width"
            >
              <div class="d-flex flex-column my-2 name-field-div w-100">
                <label
                  for="f_name"
                  class="text-start custom-label-size text-muted fw-semibold mt-1"
                  >Full Name</label
                >
                <input
                  type="text"
                  id="f_name"
                  class="form-control py-1 bg-light custom-input-style"
                  disabled
                  :value="first_name"
                />
              </div>

              <!-- <div class="d-flex flex-column my-2 name-field-div">
                <label
                  for="l_name"
                  class="text-start custom-label-size text-muted fw-semibold mt-1"
                  >Last Name</label
                >
                <input
                  type="text"
                  id="l_name"
                  class="form-control py-1 bg-light custom-input-style"
                  disabled
                  :value="last_name"
                />
              </div> -->
            </div>

            <!-- Company Name Field  -->

            <div class="d-flex flex-column my-2 custom-field-width">
              <label
                for="company_name"
                class="text-start custom-label-size text-muted fw-semibold mt-1"
                >Company Name</label
              >
              <input
                type="text"
                id="company_name"
                class="form-control py-1 bg-light custom-input-style"
                :value="companyName"
                disabled
              />
            </div>

            <!-- Mobile Number Field  -->

            <div class="d-flex flex-column my-2 custom-field-width">
              <label
                for="mobile"
                class="text-start custom-label-size text-muted fw-semibold mt-1"
                >Mobile Number</label
              >
              <input
                type="text"
                id="mobile"
                class="form-control py-1 bg-light custom-input-style"
                disabled
                :value="mobile_no"
              />
            </div>

            <!-- Create Password Field  -->

            <!-- <div class="d-flex flex-column my-2 custom-field-width">
              <label
                for="c_password"
                class="text-start custom-label-size text-muted fw-semibold mt-1"
                >Create Password</label
              >
              <input
                type="text"
                id="c_password"
                class="form-control py-1 bg-light custom-input-style"
                disabled
              />
            </div> -->

            <!-- Company Address Field  -->

            <div class="d-flex flex-column my-2 custom-field-width">
              <label
                for="c_address"
                class="text-start custom-label-size text-muted fw-semibold mt-1"
                >Company Address</label
              >
              <input
                type="text"
                id="c_address"
                class="form-control py-1 bg-light custom-input-style"
                :value="companyAddress"
                disabled
              />
            </div>

            <!-- Country/State Field  -->

            <div
              class="d-flex align-items-center justify-content-between custom-field-width"
            >
              <div class="d-flex flex-column my-2 name-field-div">
                <label
                  for="country"
                  class="text-start custom-label-size text-muted fw-semibold mt-1"
                  >Country</label
                >
                <input
                  type="text"
                  id="country"
                  class="form-control py-1 bg-light custom-input-style"
                  disabled
                  :value="country"
                />
              </div>

              <div class="d-flex flex-column my-2 name-field-div">
                <label
                  for="state"
                  class="text-start custom-label-size text-muted fw-semibold mt-1"
                  >State</label
                >
                <input
                  type="text"
                  id="state"
                  class="form-control py-1 bg-light custom-input-style"
                  disabled
                  :value="state"
                />
              </div>
            </div>

            <!-- City/Pincode Field  -->

            <div
              class="d-flex align-items-center justify-content-between custom-field-width"
            >
              <div class="d-flex flex-column my-2 name-field-div">
                <label
                  for="city"
                  class="text-start custom-label-size text-muted fw-semibold mt-1"
                  >City</label
                >
                <input
                  type="text"
                  id="city"
                  class="form-control py-1 bg-light custom-input-style"
                  disabled
                  :value="city"
                />
              </div>

              <div class="d-flex flex-column my-2 name-field-div">
                <label
                  for="pincode"
                  class="text-start custom-label-size text-muted fw-semibold mt-1"
                  >Pincode</label
                >
                <input
                  type="text"
                  id="pincode"
                  class="form-control py-1 bg-light custom-input-style"
                  disabled
                  :value="pincode"
                />
              </div>
            </div>

            <!-- Sales Manager Name Field  -->

            <div class="d-flex flex-column my-2 custom-field-width">
              <label
                for="sales_manager_name"
                class="text-start custom-label-size text-muted fw-semibold mt-1"
                >Sales Manager Name</label
              >
              <input
                type="text"
                id="sales_manager_name"
                class="form-control py-1 bg-light custom-input-style"
                :value="salseManagerCode"
                disabled
              />
            </div>

            <!-- Enter GST Number Field  -->

            <!-- <div class="d-flex flex-column my-2 custom-field-width mb-4">
              <label
                for="gst_num"
                class="text-start custom-label-size text-muted fw-semibold mt-1"
                >Enter GST Number</label
              >
              <input
                type="text"
                id="gst_num"
                class="form-control py-1 bg-light custom-input-style"
                :value="gstNumber"
                disabled
              />
            </div> -->

            <!-- Button Field  -->

            <div class="d-flex flex-column custom-field-width mt-4 pt-1">
              <button
                type="button"
                class="btn btn-primary w-100 py-2 button-color mb-0"
                @click="handleSubmitFunction"
              >
                Submit
              </button>
            </div>

            <!-- Login Click  -->

            <div
              class="d-flex align-items-center my-1 gap-1 justify-content-center custom-field-width"
            >
              <h3 class="my-0 custom-link-style">If Purchased plan?</h3>
              <router-link
                to="/login"
                class="my-0 custom-link-style text-primary-color"
                >click here</router-link
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Right Side Plan Details -->

      <div
        class="custom-plans-bg d-flex align-items-center justify-content-center flex-column position-relative pb-3"
      >
        <div class="position-absolute circle-div">
          <img src="./assets/Group_45141_2.svg" alt="circle-image" />
        </div>
        <div class="logo-div mt-4 plan-heading">
          <img
            src="./assets/Consultant_Panel.png"
            alt="Logo-Image"
            class="img-fluid"
            style="width: 20rem;"
          />
        </div>

        <div class="mt-3 mb-1 text-center plan-heading">
          <h3 class="fs-2 mb-1 fw-bold">Special Treats Just For You!</h3>
          <h5 class="fs-6 fw-bold">
            Explore Our Comprehensive Pricing Structure To Find The Perfect Plan
            For You
          </h5>
        </div>

        <div
          class="my-4 w-100 d-flex align-items-center justify-content-center"
        >
          <img src="./assets/Line_285.svg" alt="Line-Image" class="img-fluid" />
        </div>

        <div
          class="d-flex align-items-center justify-content-evenly flex-wrap w-100 plan-card-div"
        >
          <div
            class="position-relative plan-card-container"
            @click="plan_id = selectRadio('form-1year')"
          >
            <img src="./assets/Rectangle_3820_1.svg" alt="White_bg" class="" />
            <div
              class="position-absolute d-flex align-items-center justify-content-center flex-column plan-card-main-div"
              ref="one_year_div"
            >
              <div
                class="plan-card d-flex align-items-center justify-content-center flex-column mb-1 pt-3"
              >
                <h3 class="fw-bold mb-0">1 Year</h3>
                <h5 class="fw-semibold">One Payment, 1 Year Of Benefits</h5>
              </div>

              <div
                class="plan-card-price-div d-flex align-items-center justify-content-center gap-1 flex-column mt-4 position-relative"
              >
                <h3 class="fw-bold mb-0 pt-2 pb-1">29,999/-</h3>
                <h6 class="fw-semibold mb-0">1 Month</h6>
                <div
                  class="plan-card-price-tag-div position-absolute px-2 py-1"
                >
                  <h4 class="fw-semibold mb-0">Old Prize</h4>
                </div>

                <div class="position-absolute plan-card-price-cross-div mb-2">
                  <img src="./assets/Group_45139.svg" alt="cross-image" />
                </div>
              </div>

              <div class="my-4">
                <h3 class="fw-bold fs-6 mb-0">Special Offers For You</h3>
              </div>

              <div class="my-3 plan-card-price-text-div">
                <h3 class="fw-bold fs-1 mb-0 text-primary-color">
                  ₹ 2,999/<span class="fw-bold fs-5">+GST</span>
                </h3>
                <h6 class="fw-semibold my-1">1 Year Free</h6>
              </div>

              <div class="my-1 plan-card-desc-text">
                <h6 class="fw-semibold my-1">
                  Designed For Users Who Require<br />
                  More Advanced Features And Capabilities
                </h6>
              </div>

              <div class="mt-3">
                <button type="button" class="btn btn-primary py-1 px-4 fs-6">
                  Select
                </button>
              </div>
            </div>
          </div>
          <div
            class="position-relative plan-card-container"
            @click="plan_id = selectRadio('form-2year')"
          >
            <img src="./assets/Rectangle_3820_1.svg" alt="White_bg" />
            <div
              class="position-absolute d-flex align-items-center justify-content-center flex-column plan-card-main-div"
              ref="two_year_div"
            >
              <div
                class="plan-card d-flex align-items-center justify-content-center flex-column mb-1 pt-3"
              >
                <h3 class="fw-bold mb-0">2 Years</h3>
                <h5 class="fw-semibold">One Payment, 2 Years Of Benefits</h5>
              </div>

              <div
                class="plan-card-price-div d-flex align-items-center justify-content-center gap-1 flex-column mt-4 position-relative"
              >
                <h3 class="fw-bold mb-0 pt-2 pb-1">59,999/-</h3>
                <h6 class="fw-semibold mb-0">3 Month</h6>
                <div
                  class="plan-card-price-tag-div position-absolute px-2 py-1"
                >
                  <h4 class="fw-semibold mb-0">Old Prize</h4>
                </div>

                <div class="position-absolute plan-card-price-cross-div mb-2">
                  <img src="./assets/Group_45139.svg" alt="cross-image" />
                </div>
              </div>

              <div class="my-4">
                <h3 class="fw-bold fs-6 mb-0">Special Offers For You</h3>
              </div>

              <div class="my-3 plan-card-price-text-div">
                <h3 class="fw-bold fs-1 mb-0 text-primary-color">
                  ₹ 3,999/<span class="fw-bold fs-5">+GST</span>
                </h3>
                <h6 class="fw-semibold my-1">2 Year Free</h6>
              </div>

              <div class="my-1 plan-card-desc-text">
                <h6 class="fw-semibold my-1">
                  Designed For Users Who Require<br />
                  More Advanced Features And Capabilities
                </h6>
              </div>

              <div class="mt-3">
                <button type="button" class="btn btn-primary py-1 px-4 fs-6">
                  Select
                </button>
              </div>
            </div>
          </div>
          <div
            class="position-relative plan-card-container"
            @click="plan_id = selectRadio('form-lifetime')"
          >
            <img src="./assets/Rectangle_3820_1.svg" alt="White_bg" />
            <div
              class="position-absolute d-flex align-items-center justify-content-center flex-column plan-card-main-div"
              ref="life_time_div"
            >
              <div
                class="plan-card d-flex align-items-center justify-content-center flex-column mb-1 pt-3"
              >
                <h3 class="fw-bold mb-0">Lifetime Plan</h3>
                <h5 class="fw-semibold">One Payment, Lifetime Free Benefits</h5>
              </div>

              <div
                class="plan-card-price-div d-flex align-items-center justify-content-center gap-1 flex-column mt-4 position-relative"
              >
                <h3 class="fw-bold mb-0 pt-2 pb-1">29,9999/-</h3>
                <h6 class="fw-semibold mb-0">Per Anuam</h6>
                <div
                  class="plan-card-price-tag-div position-absolute px-2 py-1"
                >
                  <h4 class="fw-semibold mb-0">Old Prize</h4>
                </div>

                <div class="position-absolute plan-card-price-cross-div mb-2">
                  <img src="./assets/Group_45139.svg" alt="cross-image" />
                </div>
              </div>

              <div class="my-4">
                <h3 class="fw-bold fs-6 mb-0">Special Offers For You</h3>
              </div>

              <div class="my-3 plan-card-price-text-div">
                <h3 class="fw-bold fs-1 mb-0 text-primary-color">
                  ₹ 4,999/<span class="fw-bold fs-5">+GST</span>
                </h3>
                <h6 class="fw-semibold my-1">Lifetime Plan</h6>
              </div>

              <div class="my-1 plan-card-desc-text">
                <h6 class="fw-semibold my-1">
                  Designed For Users Who Require<br />
                  More Advanced Features And Capabilities
                </h6>
              </div>

              <div class="mt-3">
                <button type="button" class="btn btn-primary py-1 px-4 fs-6">
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal For Plan Key Of Sales Manager  -->

    <button
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
      ref="modal_id_btn"
      style="display: none"
    >
      Open Modal For Id
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h5 class="modal-title fw-semibold ms-3" id="staticBackdropLabel">
              Enter Your Plan Key
            </h5>
            <button
              type="button"
              class="btn-close shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="plan_key_close_btn"
            ></button>
          </div>
          <div class="modal-body custom-width mx-auto pt-0">
            <p class="text-danger text-center mt-2" style="font-size: 0.9rem">
              {{ modalErrorMsg }}
            </p>
            <div
              class="w-100 d-flex align-items-center justify-content-center flex-column"
            >
              <div class="d-flex flex-column my-2 w-100">
                <label
                  for="sales_key"
                  class="text-start custom-label-size text-muted fw-semibold mt-1"
                  >Enter You Plan Key:
                </label>
                <input
                  type="text"
                  id="sales_key"
                  class="form-control py-1 bg-light custom-input-style fs-5"
                  v-model="sales_plan_key"
                />
              </div>
            </div>
          </div>
          <div
            class="modal-footer justify-content-center border-0 custom-width mx-auto pb-4 pt-0"
          >
            <button
              type="button"
              class="btn btn-primary w-100 py-2 button-color mb-0"
              :disabled="loadingKeyVerify"
              @click="handleKeyFunction"
            >
              {{ loadingKeyVerify ? "Loading.." : "Submit" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </main>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Footer from "./Footer.vue";

import Swal from "sweetalert2";

import axios from "axios";

export default {
  name: "Temporary_Plan_Design",
  data() {
    return {
      // selectedSide: "left",

      // apiurl: "https://uatibconsult.infinitybrains.com/api/",
      apiurl: "https://consultantapi.infinitybrains.com/api/",

      addFreeSelected: false,
      uploadLogoSelected: false,

      selectedValue: "1",
      options: [],
      numberOfUser: "",
      userYear: "",
      userPlanAmount: "",

      formError: "",

      userDetails: [],
      userPlanDetails: [],
      userVendor: [],
      userLocation: [],

      plan_id: 4, // Plan Id: 4 For Lifetime Plan, Production.
      // plan_id: 1, // Plan Id: 1 For Lifetime Plan, Uat.

      selectedPlan: "",

      cgst: "",
      sgst: "",
      gst: "",
      amount: "",
      total_amount: "",
      first_name: "",
      last_name: "",
      mobile_no: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      uIdUpdPay: "",
      companyName: "",
      companyAddress: "",
      salseManagerCode: "",
      gstNumber: "",
      email: "",
      emailVerified: false,
      message: "",

      modalErrorMsg: "",
      sales_plan_key: "",

      paymentModeFlag: false,

      purchased_plan_id: "",
      loadingKeyVerify: false
    };
  },
  components: {
    Footer
  },
  mounted() {
    // this.makeid();
    window.scroll(0, 0);

    this.selectedPlan = this.plan_id;

    this.$refs.life_time_div.classList.add("selected-plan-class");

    if (
      localStorage.getItem("UserEmail") ||
      localStorage.getItem("UserFirstName") ||
      // localStorage.getItem("UserLastName") ||
      localStorage.getItem("UserMobileNo") ||
      localStorage.getItem("SelectedPlanId") ||
      localStorage.getItem("paymentTemporaryPlan")
    ) {
      localStorage.removeItem("UserEmail");
      localStorage.removeItem("UserFirstName");
      // localStorage.removeItem("UserLastName");
      localStorage.removeItem("UserMobileNo");
      localStorage.removeItem("SelectedPlanId");
      localStorage.removeItem("paymentTemporaryPlan");
    }
  },

  methods: {
    redirectToApp() {
      this.$router.push("/login");
    },
    selectRadio(id) {
      this.$refs.life_time_div.classList.remove("selected-plan-class");
      this.$refs.two_year_div.classList.remove("selected-plan-class");
      this.$refs.one_year_div.classList.remove("selected-plan-class");

      switch (id) {
        case "form-lifetime":
          this.plan_id = 4; // Plan Id: 4 For Lifetime Plan, Production.
          // this.plan_id = 1; // Plan Id: 1 For Lifetime Plan, Uat.
          this.$refs.life_time_div.classList.add("selected-plan-class");
          break;
        case "form-2year":
          this.plan_id = 3; // Plan Id: 3 For 2 Year Plan, Production.
          // this.plan_id = 2; // Plan Id: 2 For 2 Year Plan, Uat.
          this.$refs.two_year_div.classList.add("selected-plan-class");
          break;
        case "form-1year":
          this.plan_id = 2; // Plan Id: 2 For 1 Year Plan, Production.
          // this.plan_id = 3; // Plan Id: 3 For 1 Year Plan, Uat.
          this.$refs.one_year_div.classList.add("selected-plan-class");
          break;
        default:
          break;
      }

      this.selectedPlan = this.plan_id;

      // console.log("Selected Plan: ", this.selectedPlan);

      if (this.paymentModeFlag == true) {
        // If Payment flag true at that time call one api with
        // payment mode: 1, email and sales manager plan key
        // it gives plan amount and purchased plan id and store this in local storage.

        const data = {
          email: this.email,
          plan_id: this.selectedPlan,
          payment_mode: "1"
        };

        axios
          .post(`${this.apiurl}subscription-users`, data)
          .then(res => {
            // console.log("response of CRM: ", res);
            // console.log("Sales Limit: ", res.data.sales_limit.limit);

            if (res.data.sales_limit.limit > 0) {
              this.purchased_plan_id = res.data.plan.id;
            } else {
              Swal.fire({
                icon: "error",
                title: "Unable to select Plan!!",
                text:
                  "You does not have limit for this plan, \nPlease Choose another one."
              });
            }
          })
          .catch(err => {
            // console.log("Error Inside CRM: ", err);
            if (err.response.data.status_code == 404) {
              Swal.fire({
                icon: "error",
                title: "Unable to select Plan!!",
                text:
                  "You does not have limit for this plan, \nPlease Choose another one."
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Unable to select Plan!!",
                text: "Please try again after some time."
              });
            }
          });
      }
    },

    handleKeyFunction() {
      if (this.sales_plan_key != "") {
        this.loadingKeyVerify = true;
        // console.log("Sales Plan Key: ", this.sales_plan_key);

        // Call Api for checking plan key from sales manager
        // and redirected to success page
        // and api already updating payment status for purchased plan

        const data = {
          privacy_key: this.sales_plan_key
        };

        axios
          .post(
            `${this.apiurl}subscription-users-keyvarify/${this.purchased_plan_id}`,
            data
          )
          .then(res => {
            // console.log("Response of Verifying Secret Key: ", res);
            this.sales_plan_key = "";
            this.loadingKeyVerify = false;
            this.$refs.plan_key_close_btn.click();
            if (res.data.status_code == 200) {
              Swal.fire({
                icon: "success",
                title: "Key Verification Done",
                text: "You Key has been verified successfully !!"
              }).then(() => {
                this.$router.push("/success");
              });
            } else {
              Swal.fire({
                icon: "info",
                title: "Something gets wrong!",
                text: "Unable to verify your key, Please check your key once."
              });
            }
          })
          .catch(err => {
            this.loadingKeyVerify = false;
            this.$refs.plan_key_close_btn.click();
            console.log("Error in Verifying Secret Key: ", err);
            this.sales_plan_key = "";

            if (err.response.data.status_code == 404) {
              Swal.fire({
                icon: "error",
                title: "Unable to verify your key!!",
                text: `${err.response.data.message}`
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Unable to verify your key!!",
                text: `${err.response.data.message}`
              });
            }
          });

        // this.$router.push("/success");
      } else {
        this.modalErrorMsg = "Please enter valid plan key !!";
        setTimeout(() => {
          this.modalErrorMsg = "";
        }, 1000);
      }
    },

    handleSubmitFunction() {
      // console.log("Button Clicked !!");
      // console.log("Plan Id: ", this.selectedPlan);
      if (this.email != "" && this.email != null) {
        if (this.paymentModeFlag == false) {
          // Online Payment because payment flag was false and sales manager does not
          // have any limit
          localStorage.setItem("UserEmail", this.email);
          localStorage.setItem("UserFirstName", this.first_name);
          // localStorage.setItem("UserLastName", this.last_name);
          localStorage.setItem("UserMobileNo", this.mobile_no);
          localStorage.setItem("SelectedPlanId", this.selectedPlan);
          this.$router.push("/TemporaryPlansBilling");
        } else {
          // Offline Payment because payment flag was true and sales manager have
          // limit
          // localStorage.setItem("UserEmail", this.email);
          // localStorage.setItem("UserFirstName", this.first_name);
          // localStorage.setItem("UserLastName", this.last_name);
          // localStorage.setItem("UserMobileNo", this.mobile_no);
          // localStorage.setItem("SelectedPlanId", this.selectedPlan);

          this.$refs.modal_id_btn.click();
        }
      } else {
        console.log("Inside Else !!");
        this.formError = "Please verify your email address first !!";
        setTimeout(() => {
          this.formError = "";
        }, 3000);
      }
    },
    async getEmail() {
      //   console.log("Plan Id From Email Verify: ", this.selectedPlan);
      // Check if the email is empty
      if (!this.email.trim()) {
        this.formError = "Please enter your email address.";
        return;
      }

      try {
        const response = await axios.post(`${this.apiurl}users-getdetails`, {
          email: this.email
        });

        // console.log("API Response From Email Verification:", response.data);

        if (
          response.data.status === true &&
          response.data.status_code === 200
        ) {
          this.isEmailVerified = true;
          // Store the user information from the API response
          this.userDetails = response.data.data;
          // this.userPlanDetails = response.data.plan;
          // this.userVendor = response.data.vendor;
          // this.userLocation = response.data.location;

          // access the data of user details from api
          this.email = this.userDetails.email;
          this.first_name = this.userDetails.full_name;
          // this.last_name = this.userDetails.last_name;
          this.mobile_no = this.userDetails.mobile;
          // this.companyName = this.userDetails.company_name;
          // this.companyAddress = this.userDetails.address;

          // user location
          // this.country = this.userLocation.country_id_details.country_id_name;
          // this.state = this.userLocation.state_id_details.state_id_name;
          // this.city = this.userLocation.city_id_details.city_id_name;
          // this.pincode = this.userDetails.pin_code;

          this.salseManagerCode = this.userDetails.sales_manager_code;
          // this.gstNumber = this.userDetails.gstNumber;

          // Manage Payment Flag Based on Sales Manager Limit Response
          // from this api response
          // Payment Flag true = Offline Payment
          // Payment Flag false = Online Payment

          if (response.data.total_limit != null) {
            this.paymentModeFlag = true;
            // console.log("Payment Mode: ", this.paymentModeFlag);
          } else {
            this.paymentModeFlag = false;
            // console.log("Payment Mode: ", this.paymentModeFlag);
          }

          // this.countryDetails=response.data
          //   console.log("User Details = ", this.userDetails);
          //   console.log("User Plan Details = ", this.userPlanDetails);
          //   console.log("User vendors = ", this.userVendor);
          //   console.log("User Locations = ", this.userLocation);

          //   this.sendBillingInfo();
        } else {
          this.formError = "User not found. Enter a valid email address.";
          setTimeout(() => {
            this.formError = "";
          }, 5000);
          console.error("Error verifying email else:", response.data.message);
        }
      } catch (error) {
        this.formError = error.response.data.message;
        setTimeout(() => {
          this.formError = "";
        }, 5000);
        console.error("Error verifying email:", error);
      }
    }
  }
};
</script>

<style scoped>
.plan-card-container h3 {
  margin: 0;
}

.h5,
h5 {
  margin-bottom: 0.5rem;
}

.form-control {
  background-clip: padding-box !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 0.375rem !important;
}

input:focus {
  border-color: #0c2d61;
}

button {
  border-color: #0c2d61 !important;
}

button:active {
  background-color: none !important;
}

button:hover {
  opacity: 0.9;
  background-color: inherit !important;
  color: #0c2d61;
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: #2d396c;
  border-color: #0c2d61;
}

.custom-width {
  width: 90%;
}

.custom-form-bg {
  width: 32%;
  height: 53rem;
  background-color: #ffffff;
}

.custom-left-side-width {
  width: 80%;
}

.sub-title-text-size {
  font-size: 0.75rem;
}

.doted-line-div {
  width: 350px;
  height: 5px;
  border-bottom: 2px dashed black;
}

.custom-field-width {
  width: 91%;
}

.custom-label-size {
  width: fit-content;
  font-size: 0.6rem;
  margin-left: 0.1rem;
  margin-bottom: 0.2rem;
}

.custom-email-input-style input {
  width: 100%;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  cursor: pointer;
}

.input-group-focus,
.form-control {
  box-shadow: none !important;
}

.custom-email-input-style button {
  width: 25%;
  cursor: pointer;
  z-index: 1 !important;
  position: absolute;
  right: 0;
}

.custom-input-style {
  width: 100%;
  cursor: pointer;
}

.name-field-div {
  width: 47%;
}

.custom-link-style {
  font-size: 0.7rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.btn-primary {
  background-color: #0c2d61 !important;
}

.custom-plans-bg {
  width: 70%;
  height: 53rem;
  background-color: #f5f7ff;
}

.circle-div {
  top: -105px;
  right: -120px;
}

.plan-card-container {
  cursor: pointer;
}

.plan-card-main-div {
  top: 1.68rem;
  right: 0.5rem;
  width: 17.8rem;
  height: 31rem;
}

.plan-card {
  width: 17rem;
  height: auto;
}

.plan-card-main-div:hover {
  border: 2.5px solid #0c2d61;
  border-radius: 10px;
}

.selected-plan-class {
  border: 2.5px solid #0c2d61;
  border-radius: 10px;
}

.plan-heading h3 {
  margin: 0;
}

.plan-card h3 {
  width: fit-content;
  font-size: 1.5rem;
}

.plan-card h5 {
  width: fit-content;
  font-size: 0.7rem;
}

.plan-card-price-div {
  width: fit-content;
  border: 3px solid black;
  border-radius: 6px;
  padding: 0.8rem 1.8rem;
}

.plan-card-price-div h3 {
  font-size: 2.3rem;
}

.plan-card-price-div h6 {
  font-size: 0.8rem;
}

.plan-card-price-tag-div {
  background-color: #ffffff;
  top: -13px;
}

.plan-card-price-tag-div h4 {
  font-size: 0.8rem;
}

.text-primary-color {
  color: #0c2d61 !important;
}

.plan-card-price-text-div h6 {
  font-size: 0.65rem;
  text-align: center;
}

.plan-card-desc-text h6 {
  font-size: 0.65rem;
  text-align: center;
}

@media screen and (max-width: 325px) {
}

@media screen and (max-width: 576) {
}

@media screen and (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }
  .custom-form-bg {
    width: 100%;
    z-index: 1;
    justify-content: center;
    padding-bottom: 1.5rem;
  }

  .custom-left-side-width {
    width: 95%;
    margin: 0 auto !important;
  }

  .custom-field-width {
    width: 100%;
  }

  .custom-plans-bg {
    width: 100%;
  }

  .plan-heading {
    z-index: 1;
  }

  .plan-card-div {
    width: inherit;
    min-height: inherit;
    overflow-y: auto;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .plan-card-div {
    width: inherit;
    min-height: inherit;
    overflow-y: auto;
  }

  .custom-form-bg {
    width: 45%;
  }

  .plan-heading {
    z-index: 1;
  }

  .custom-plans-bg {
    width: 55%;
  }
}

@media screen and (min-width: 993px) and (max-width: 1024px) {
  .plan-card-div {
    width: inherit;
    min-height: inherit;
    overflow-y: auto;
  }
  .custom-form-bg {
    width: 40%;
  }

  .custom-plans-bg {
    width: 60%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .plan-card-div {
    width: inherit;
    min-height: inherit;
    overflow-y: auto;
  }

  .custom-form-bg {
    width: 35%;
  }

  .custom-plans-bg {
    width: 65%;
  }
}

@media screen and (min-width: 1440px) {
}
</style>