<template>
  <div class="custom-main-container">
    <form class="custom-form-container" v-if="!emailVerified">
      <!-- Contact Information -->
      <div class="custom-row">
        <div class="" style="width: 100%;">
          <label for="email" class="custom-label">Email</label>
          <input type="email" id="email" v-model="email" class="custom-input" />
          <small id="emailHelp" class="form-text text-muted my-3"
            >We'll never share your email with anyone else.</small
          >
        </div>
      </div>

      <div class="custom-row" style="margin: 1rem;">
        <div class="" style="width: 100%;">
          <label for="number" class="custom-label">Number</label>
          <input
            type="number"
            id="number"
            v-model="number"
            class="custom-input"
          />
        </div>
      </div>

      <div class="custom-row">
        <div class="" style="width: 100%;">
          <label for="password" class="custom-label">Password</label>
          <input
            type="password"
            id="password"
            v-model="password"
            class="custom-input"
          />
        </div>
      </div>

      <div class="mt-3">
        <button
          v-if="!emailVerified"
          @click.prevent="verifyEmail"
          class="custom-btn"
        >
          Verify
        </button>
      </div>
      <div v-if="message" class="mt-3 text-danger fs-bolder">{{ message }}</div>
    </form>

    <div class="custom-billing-container" v-if="emailVerified">
      <h2 class="lg-font-size" style="color: #2f518a;">
        BILLING INFORMATION
      </h2>

      <div class="custom-billing" style="color: #2f518a;">
        <h3 class="md-font-size">Product Name</h3>
        <p class="md-font-size">Rate(₹)</p>
      </div>

      <div
        style="border-bottom: 3px solid #a39d9d; width: 100%; margin: 1rem;"
      ></div>

      <div class="custom-billing">
        <div>
          <h5 class="md-font-size">Sub Total</h5>
        </div>
        <div>
          <p>{{ subscriptionUser.amount }}</p>
        </div>
      </div>

      <div style="display: flex; width: 100%;">
        <h6 style="text-decoration: underline;">Gst:</h6>
      </div>

      <div class="custom-billing">
        <div>
          <h6>GST(9%)</h6>
          <h6>C-GST(9%)</h6>
          <h6>S-GST(9%)</h6>
        </div>
        <div>
          <p>{{ subscriptionUser.gst }}</p>
          <p>{{ subscriptionUser.cgst }}</p>
          <p>{{ subscriptionUser.sgst }}</p>
        </div>
      </div>

      <div
        style="border-bottom: 3px dotted #a39d9d; width: 100%; margin: 1rem;"
      ></div>

      <div class="custom-billing">
        <h4 class="md-font-size">Total Payment:</h4>
        <p>{{ subscriptionUser.total_amount }}</p>
      </div>

      <button class="custom-btn" @click="payNow" :disabled="!emailVerified">
        PAY NOW
      </button>
    </div>

    <!-- PayU form -->
    <!-- <form method="POST" class="pl-5 pr-5" id="paymentForm" :action="payuUrl"> -->
    <form
      method="POST"
      class="pl-5 pr-5"
      id="paymentForm"
      :action="payuUrl"
      @submit.prevent="generateHashAndSubmitForm"
    >
      <input type="hidden" name="key" v-model="mkey" size="64" />
      <input type="hidden" name="txnid" v-model="txnid" size="64" />

      <!-- <input type="hidden" name="amount" v-model="amount_pay" size="64" /> -->
      <input
        type="hidden"
        name="amount"
        v-model="subscriptionUser.total_amount"
        size="64"
      />

      <input type="hidden" name="productinfo" v-model="productInfo" size="64" />

      <input
        type="hidden"
        name="firstname"
        v-model="userDetails.full_name"
        size="64"
      />
      <input
        type="hidden"
        name="service_provider"
        value="payu_paisa"
        size="64"
      />
      <input type="hidden" name="email" v-model="userDetails.email" size="64" />

      <input
        type="hidden"
        name="phone"
        v-model="userDetails.mobile"
        size="64"
      />

      <!-- <input
        type="hidden"
        name="lastname"
        v-model="userDetails.last_name"
        size="64"
      /> -->
      <input type="hidden" name="surl" v-model="surl" />
      <input type="hidden" name="furl" v-model="furl" />
      <input type="hidden" name="hash" id="hash" v-model="hash" size="64" />
    </form>
  </div>
</template>

<script>
import axios from "axios";
import sha512 from "js-sha512";

export default {
  name: "VerifyEmail",
  data() {
    return {
      email: "",
      number: "",
      password: "",
      emailVerified: false,
      message: "",
      userDetails: [],
      subscriptionUser: {},

      // amount_pay: '678.64',
      hash: "",
      txnid: "",
      productInfo: "1YearPlan",
      payuUrl: "https://secure.payu.in/_payment",
      mkey: "nxpvv9VZ",
      saltKey: "3oFxUMtWG2",
      surl: `${window.location.origin}/Success`,
      furl: "https://consultant.infinitybrains.com/login/",
    };
  },

  mounted() {
    this.makeid();
  },

  methods: {
    async verifyEmail() {
      try {
        // Simulate API registration
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}register`,
          {
            email: this.email,
            mobile: this.number,
            password: this.password,
            is_freetrial: "0",
            subscription_planid: "1",
          }
        );

        if (response.data.status_code === 200) {
          this.emailVerified = true;
          this.message = response.data.message;
          this.userDetails = response.data.data;
          console.log(this.userDetails);
          this.subscriptionUser = response.data.subscription_user;
          console.log(this.subscriptionUser);
        } else {
          this.message = "Registration failed. Please try again.";
        }
      } catch (error) {
        console.error("Registration failed:", error);
        this.message = "Registration failed. Please try again.";
      }
    },

    payNow() {
      if (this.emailVerified) {
        this.generateHashAndSubmitForm();
      }
    },

    generateHashAndSubmitForm() {
      const data =
        this.mkey +
        "|" +
        this.txnid +
        "|" +
        // this.amount_pay +
        this.subscriptionUser.total_amount +
        "|" +
        this.productInfo +
        "|" +
        this.userDetails.full_name +
        "|" +
        this.userDetails.email +
        "|||||||||||";

      this.hash = sha512(data + this.saltKey);

      if (this.hash) {
        localStorage.setItem("hash", this.hash);
        localStorage.setItem("expireSession", "sesion12dgtdb");
      }

      console.log(this.hash);
      console.log(data);

      document.getElementById("hash").value = this.hash;

      const paymentSuccessful = true;

      if (paymentSuccessful) {
        this.updatePaymentStatus();
      }

      document.getElementById("paymentForm").submit();
    },

    async updatePaymentStatus() {
      try {
        const response = await axios.post(
          `https://consultantapi.infinitybrains.com/api/update-payment-status/${this.subscriptionUser.id}`,
          {
            payment_status: "1",
          }
        );

        if (response.data.status === true) {
          // Payment status successfully updated
          console.log("Payment status updated successfully.");
        } else {
          // Payment status update failed, show an error message
          console.error(
            "Error updating payment status:",
            response.data.message
          );
        }
      } catch (error) {
        // Handle API request error
        console.error("API request error:", error);
      }
    },

    makeid() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 20; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      this.txnid = text;
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
}
.custom-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-form-container {
  box-shadow: 0px 0px 10px black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding: 2rem;
  border-radius: 1rem;
  background-color: white;
}
.custom-row {
  display: flex;
  width: 100%;
}

.custom-col-6 {
  margin: 0 1rem;
}

.custom-input,
.custom-select {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #d4c8c859;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0px 0px 10px #d4c8c859;
}

.custom-label {
  font-weight: bold;
  font-size: 1rem;
}
.custom-btn {
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #007bff;
  border: 1px solid #007bff;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  margin-top: 1rem;
  cursor: pointer;
}

.custom-btn:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.custom-billing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
  box-shadow: 1px 0px 10px black;
  border-radius: 1rem;
  padding: 3rem;
  background-color: white;
}
.custom-billing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.lg-font-size {
  font-size: 1.8rem;
  font-weight: bolder;
  text-decoration: underline;
}

.md-font-size {
  font-size: 1.2rem;
  font-weight: bolder;
  color: black;
}

.sm-font-size {
  font-size: 0.9rem;
}

@media (max-width: 932px) {
  .custom-main-container {
    flex-direction: column;
  }
  .custom-form-container {
    width: 75%;
  }
  .custom-billing-container {
    margin-top: 1rem;
    margin-left: 0;
    width: 75%;
  }
}
@media (max-width: 499px) {
  .custom-form-container {
    width: 100%;
  }
  .custom-billing-container {
    margin-top: 1rem;
    margin-left: 0;
    width: 100%;
  }
}
</style>
